import luminis1 from "../src/work.imges2/Rectangle 11.png";
import luminis2 from "../src/work.imges2/Rectangle 12.png";
import luminis3 from "../src/work.imges2/Rectangle 13.png";
import luminis4 from "../src/work.imges2/Rectangle 14.png";
import luminis5 from "../src/work.imges2/Rectangle 15.png";
import luminis6 from "../src/work.imges2/Rectangle 16.png";
import luminis7 from "../src/work.imges2/Rectangle 17.png";

import luminis9 from "../src/work.imges2/Rectangle 19 (1).png";
import luminis10 from "../src/work.imges2/Rectangle 20 (1).png";
import luminis11 from "../src/work.imges2/Rectangle 21.png";
import luminis13 from "../src/work.imges2/Rectangle 23.png";
import luminis14 from "../src/work.imges2/Rectangle 24.png";
import luminis15 from "../src/work.imges2/Rectangle 25.png";
import luminis16 from "../src/work.imges2/Rectangle 2.png";
import luminis17 from "../src/work.imges2/Rectangle 3.png";
import luminis18 from "../src/work.imges2/Rectangle 4.png";
import luminis19 from "../src/work.imges2/Rectangle 5.png";
import luminis20 from "../src/work.imges2/Rectangle 6.png";
import luminis21 from "../src/work.imges2/Rectangle 7.png";
import luminis22 from "../src/work.imges2/Rectangle 8.png";
import luminis23 from "../src/work.imges2/Rectangle 9.png";
import buyr1 from "../src/work.imges2/buyr  (1).png";
import buyr2 from "../src/work.imges2/buyr (2).png";
import buyr3 from "../src/work.imges2/buyr (3).png";
import buyr4 from "../src/work.imges2/buyr (4).png";
import buyr5 from "../src/work.imges2/buyr (5).png";
import buyr6 from "../src/work.imges2/buyr (6).png";
import buyr7 from "../src/work.imges2/buyr  (8).png";
import buyr8 from "../src/work.imges2/buyr (9).png";
import buyr9 from "../src/work.imges2/buyr (10).png";
import buyr10 from "../src/work.imges2/buyr (11).png";
import buyr11 from "../src/work.imges2/buyr (12).png";
import buyr12 from "../src/work.imges2/buyr (13).png";
import buyr13 from "../src/work.imges2/buyr (14).png";
import buyr14 from "../src/work.imges2/buyr (15).png";
import buyr15 from "../src/work.imges2/buyr (16).png";
import glassof1 from "../src/work.imges2/glassOf (0).png";
import glassof2 from "../src/work.imges2/glassOf (1).png";
import glassof3 from "../src/work.imges2/glassOf (2).png";
import glassof4 from "../src/work.imges2/glassOf (3).png";
import glassof5 from "../src/work.imges2/glassOf (4).png";
import glassof6 from "../src/work.imges2/glassOf (5).png";
import glassof7 from "../src/work.imges2/glassOf (6).png";
import glassof8 from "../src/work.imges2/glassOf (7).png";
import glassof9 from "../src/work.imges2/glassOf (8).png";
import glassof10 from "../src/work.imges2/glassOf (9).png";
import glassof11 from "../src/work.imges2/glassOf (10).png";
import glassof12 from "../src/work.imges2/glassOf (11).png";
import glassof13 from "../src/work.imges2/glassOf (12).png";
import glassof14 from "../src/work.imges2/glassOf (13).png";
import glassof15 from "../src/work.imges2/glassOf (14).png";
import glassof16 from "../src/work.imges2/glassOf (14).png";
import poeme1 from "../src/work.imges2/poeme (01).png";
import poeme2 from "../src/work.imges2/poeme (02).png";
import poeme3 from "../src/work.imges2/poeme (03).png";
import poeme4 from "../src/work.imges2/poeme (04).png";
import poeme5 from "../src/work.imges2/poeme (05).png";
import poeme6 from "../src/work.imges2/poeme (06).png";
import poeme7 from "../src/work.imges2/poeme (07).png";
import poeme8 from "../src/work.imges2/poeme (08).png";
import poeme9 from "../src/work.imges2/poeme (09).png";
import poeme10 from "../src/work.imges2/poeme (10).png";
import poeme11 from "../src/work.imges2/poeme (11).png";
import poeme12 from "../src/work.imges2/poeme (12).png";
import poeme13 from "../src/work.imges2/poeme (13).png";
import poeme14 from "../src/work.imges2/poeme (14).png";
import poeme15 from "../src/work.imges2/poeme (15).png";
import poeme16 from "../src/work.imges2/poeme (16).png";
import poeme17 from "../src/work.imges2/poeme (17).png";
import poeme18 from "../src/work.imges2/poeme (18).png";
import poeme19 from "../src/work.imges2/poeme (19).png";
import ofus1 from "../src/work.imges2/ofus (01).png";
import ofus2 from "../src/work.imges2/ofus (02).png";
import ofus3 from "../src/work.imges2/ofus (03).png";
import ofus4 from "../src/work.imges2/ofus (04).png";
import ofus5 from "../src/work.imges2/ofus (05).png";
import ofus6 from "../src/work.imges2/ofus (06).png";
import ofus7 from "../src/work.imges2/ofus (07).png";
import ofus8 from "../src/work.imges2/ofus (08).png";
import ofus9 from "../src/work.imges2/ofus (09).png";
import ofus10 from "../src/work.imges2/ofus (010).png";
import ofus11 from "../src/work.imges2/ofus (011).png";
import ofus12 from "../src/work.imges2/ofus (012).png";
import ofus13 from "../src/work.imges2/ofus (013).png";
import ofus14 from "../src/work.imges2/ofus (014).png";
import ofus15 from "../src/work.imges2/ofus (015).png";
import ofus16 from "../src/work.imges2/ofus (016).png";
import ofus17 from "../src/work.imges2/ofus (017).png";
import ofus18 from "../src/work.imges2/ofus (018).png";
import ofus19 from "../src/work.imges2/ofus (019).png";
import ofus20 from "../src/work.imges2/ofus (020).png";
import ofus21 from "../src/work.imges2/ofus (021).png";
// gree Zone

import green1 from "../src/work.imges2/greenZ (1).png";
import green2 from "../src/work.imges2/greenZ (2).png";
import green3 from "../src/work.imges2/greenZ (3).png";
import green4 from "../src/work.imges2/greenZ (4).png";
import green5 from "../src/work.imges2/greenZ (5).png";
import green6 from "../src/work.imges2/greenZ (6).png";
import green7 from "../src/work.imges2/greenZ (7).png";
import green8 from "../src/work.imges2/greenZ (8).png";
import green9 from "../src/work.imges2/greenZ (9).png";
import green10 from "../src/work.imges2/greenZ (10).png";
import green11 from "../src/work.imges2/greenZ (11).png";
import green12 from "../src/work.imges2/greenZ (12).png";
import green13 from "../src/work.imges2/greenZ (13).png";
import green14 from "../src/work.imges2/greenZ (14).png";
import green15 from "../src/work.imges2/greenZ (15).png";
import green16 from "../src/work.imges2/greenZ (16).png";
import green17 from "../src/work.imges2/greenZ (17).png";
import green18 from "../src/work.imges2/greenZ (18).png";
import green19 from "../src/work.imges2/greenZ (19).png";
import green20 from "../src/work.imges2/greenZ (20).png";
import green21 from "../src/work.imges2/greenZ (21).png";
import green22 from "../src/work.imges2/greenZ (22).png";
import green23 from "../src/work.imges2/greenZ (23).png";
import green24 from "../src/work.imges2/greenZ (24).png";
import green25 from "../src/work.imges2/greenZ (25).png";
import green26 from "../src/work.imges2/greenZ (26).png";
import green27 from "../src/work.imges2/greenZ (27).png";
import green28 from "../src/work.imges2/greenZ (28).png";
import green29 from "../src/work.imges2/greenZ (29).png";
import green30 from "../src/work.imges2/greenZ (30).png";
import green31 from "../src/work.imges2/greenZ (31).png";
import green32 from "../src/work.imges2/greenZ (32).png";
import green33 from "../src/work.imges2/greenZ (33).png";
import green34 from "../src/work.imges2/greenZ (34).png";
import green35 from "../src/work.imges2/greenZ (35).png";
import green36 from "../src/work.imges2/greenZ (36).png";
import green37 from "../src/work.imges2/greenZ (37).png";
import green38 from "../src/work.imges2/greenZ (38).png";
import green39 from "../src/work.imges2/greenZ (39).png";
import green40 from "../src/work.imges2/greenZ (40).png";
import green41 from "../src/work.imges2/greenZ (41).png";
import green42 from "../src/work.imges2/greenZ (42).png";
import green43 from "../src/work.imges2/greenZ (43).png";
import green44 from "../src/work.imges2/greenZ (44).png";
import green45 from "../src/work.imges2/greenZ (45).png";
import green46 from "../src/work.imges2/greenZ (46).png";
import green47 from "../src/work.imges2/greenZ (47).png";
import green48 from "../src/work.imges2/greenZ (48).png";
import green49 from "../src/work.imges2/greenZ (49).png";
import green50 from "../src/work.imges2/greenZ (50).png";
import green51 from "../src/work.imges2/greenZ (51).png";
import hoode1 from "../src/work.imges2/hoode (1).png";
import hoode$2 from "../src/work.imges2/eb1c67152029583 1.png";
import hoode2 from "../src/work.imges2/hoode (2).png";
import hoode3 from "../src/work.imges2/hoode (3).png";
// import hoode4 from "../src/work.imges2/hoode (4).png";
import hoode5 from "../src/work.imges2/hoode (5).png";
import hoode6 from "../src/work.imges2/hoode (6).png";
import hoode7 from "../src/work.imges2/hoode (7).png";
import hoode8 from "../src/work.imges2/hoode (8).png";
import hoode9 from "../src/work.imges2/hoode (9).png";
import hoode10 from "../src/work.imges2/hoode (10).png";
import hoode11 from "../src/work.imges2/hoode (11).png";
import hoode12 from "../src/work.imges2/hoode (12).png";
import hoode13 from "../src/work.imges2/hoode (13).png";
import hoode14 from "../src/work.imges2/hoode (14).png";
import hoode15 from "../src/work.imges2/hode1 (1).png";
import hoode16 from "../src/work.imges2/hode1 (2).png";
import hoode17 from "../src/work.imges2/hode1 (3).png";
import hoode18 from "../src/work.imges2/hode1 (4).png";
import hoode19 from "../src/work.imges2/hode1 (5).png";
import hoode20 from "../src/work.imges2/hode1 (6).png";
import hoode21 from "../src/work.imges2/hode1 (7).png";
import hoode22 from "../src/work.imges2/hode1 (8).png";
import hoode23 from "../src/work.imges2/hode1 (9).png";
import hoode24 from "../src/work.imges2/hode1 (10).png";
import hoode25 from "../src/work.imges2/hode1 (11).png";
import hoode26 from "../src/work.imges2/hode1 (12).png";
import lolli1 from "../src/work.imges2/lolli (1).png";
import lolli2 from "../src/work.imges2/lolli (2).png";
import lolli3 from "../src/work.imges2/lolli (3).png";
import lolli4 from "../src/work.imges2/lolli (4).png";
import lolli5 from "../src/work.imges2/lolli (5).png";
import lolli6 from "../src/work.imges2/lolli (6).png";
import lolli7 from "../src/work.imges2/lolli (7).png";
import lolli8 from "../src/work.imges2/lolli (8).png";
import lolli9 from "../src/work.imges2/lolli (9).png";
import lolli10 from "../src/work.imges2/lolli (10).png";
import lolli11 from "../src/work.imges2/lolli (11).png";
import lolli12 from "../src/work.imges2/lolli (12).png";
import lolli13 from "../src/work.imges2/lolli (13).png";
import lolli14 from "../src/work.imges2/lolli (14).png";
import lolli15 from "../src/work.imges2/lolli (15).png";
import lolli16 from "../src/work.imges2/lolli (16).png";
import mandarin1 from "../src/work.imges2/mandarin (1).png";
import mandarin2 from "../src/work.imges2/mandarin (2).png";
import mandarin3 from "../src/work.imges2/mandarin (3).png";
import mandarin4 from "../src/work.imges2/mandarin (4).png";
import mandarin5 from "../src/work.imges2/mandarin (5).png";
import mandarin6 from "../src/work.imges2/mandarin (6).png";
import mandarin7 from "../src/work.imges2/mandarin (7).png";
import mandarin8 from "../src/work.imges2/mandarin (8).png";
import mandarin9 from "../src/work.imges2/mandarin (9).png";
import mandarin10 from "../src/work.imges2/mandarin (10).png";
import mandarin11 from "../src/work.imges2/mandarin (11).png";
import mandarin12 from "../src/work.imges2/mandarin (12).png";
import mandarin13 from "../src/work.imges2/mandarin (13).png";
import mandarin14 from "../src/work.imges2/mandarin (14).png";
import mandarin15 from "../src/work.imges2/mandarin (15).png";
import mandarin16 from "../src/work.imges2/mandarin (16).png";
import mandarin17 from "../src/work.imges2/mandarin (17).png";
import mandarin18 from "../src/work.imges2/mandarin (18).png";
import meliqsetyans1 from "../src/work.imges2/meliqsetyans (1).png";
import meliqsetyans2 from "../src/work.imges2/meliqsetyans (2).png";
import meliqsetyans3 from "../src/work.imges2/meliqsetyans (3).png";
import meliqsetyans4 from "../src/work.imges2/meliqsetyans (4).png";
import meliqsetyans5 from "../src/work.imges2/meliqsetyans (5).png";
import meliqsetyans6 from "../src/work.imges2/meliqsetyans (6).png";
import meliqsetyans7 from "../src/work.imges2/meliqsetyans (7).png";
import meliqsetyans8 from "../src/work.imges2/meliqsetyans (8).png";
import meliqsetyans9 from "../src/work.imges2/meliqsetyans (9).png";
import meliqsetyans10 from "../src/work.imges2/meliqsetyans (10).png";
import meliqsetyans11 from "../src/work.imges2/meliqsetyans (11).png";
import meliqsetyans12 from "../src/work.imges2/meliqsetyans (12).png";
import meliqsetyans13 from "../src/work.imges2/meliqsetyans (13).png";
import meliqsetyans14 from "../src/work.imges2/meliqsetyans (14).png";
import meliqsetyans15 from "../src/work.imges2/meliqsetyans (15).png";
import meliqsetyans16 from "../src/work.imges2/meliqsetyans (16).png";
import meliqsetyans17 from "../src/work.imges2/meliqsetyans (17).png";
import meliqsetyans18 from "../src/work.imges2/meliqsetyans (18).png";
import meliqsetyans19 from "../src/work.imges2/meliqsetyans (19).png";
import meliqsetyans20 from "../src/work.imges2/meliqsetyans (20).png";
import giftSop1 from "../src/work.imges2/giftshop (1).png";
import giftSop2 from "../src/work.imges2/giftshop (2).png";
import giftSop3 from "../src/work.imges2/giftshop (3).png";
import giftSop4 from "../src/work.imges2/giftshop (4).png";
import giftSop5 from "../src/work.imges2/giftshop (5).png";
import giftSop6 from "../src/work.imges2/giftshop (6).png";
import giftSop7 from "../src/work.imges2/giftshop (7).png";
import giftSop8 from "../src/work.imges2/giftshop (8).png";
import giftSop9 from "../src/work.imges2/giftshop (9).png";
import giftSop10 from "../src/work.imges2/giftshop (10).png";
import giftSop11 from "../src/work.imges2/giftshop (11).png";
import giftSop12 from "../src/work.imges2/giftshop (12).png";
import giftSop13 from "../src/work.imges2/giftshop (13).png";
import giftSop14 from "../src/work.imges2/giftshop (14).png";
import giftSop15 from "../src/work.imges2/giftshop (15).png";
import giftSop16 from "../src/work.imges2/giftshop (16).png";
import giftSop17 from "../src/work.imges2/giftshop (17).png";
import giftSop18 from "../src/work.imges2/giftshop (18).png";
import giftSop19 from "../src/work.imges2/giftshop (19).png";
import donut1 from "../src/work.imges2/donut (1).png";
import donut2 from "../src/work.imges2/donut (2).png";
import donut3 from "../src/work.imges2/donut (3).png";
import donut4 from "../src/work.imges2/donut (4).png";
import donut5 from "../src/work.imges2/donut (5).png";
import donut6 from "../src/work.imges2/donut (6).png";
import donut7 from "../src/work.imges2/donut (7).png";
import donut8 from "../src/work.imges2/donut (8).png";
import donut9 from "../src/work.imges2/donut (9).png";
import donut10 from "../src/work.imges2/donut (10).png";
import donut11 from "../src/work.imges2/donut (11).png";
import donut12 from "../src/work.imges2/donut (12).png";
import donut13 from "../src/work.imges2/donut (13).png";
import donut14 from "../src/work.imges2/donut (14).png";
import donut15 from "../src/work.imges2/donut (15).png";
import donut16 from "../src/work.imges2/donut (16).png";
import lusnazard1 from "../src/work.imges2/lusnazard (1).png";
import lusnazard2 from "../src/work.imges2/lusnazard (2).png";
import lusnazard3 from "../src/work.imges2/lusnazard (3).png";
import lusnazard4 from "../src/work.imges2/lusnazard (4).png";
import lusnazard5 from "../src/work.imges2/lusnazard (5).png";
import lusnazard6 from "../src/work.imges2/lusnazard (6).png";
import lusnazard7 from "../src/work.imges2/lusnazard (7).png";
import lusnazard8 from "../src/work.imges2/lusnazard (8).png";
import lusnazard9 from "../src/work.imges2/lusnazard (9).png";
// import lusnazard10 from "../src/work.imges2/lusnazard (010).png";
import lusnazard11 from "../src/work.imges2/lusnazard (11).png";
import lusnazard12 from "../src/work.imges2/lusnazard (12).png";
import lusnazard001 from "../src/work.imges2/lusnazard (10).png";
import lusnazard13 from "../src/work.imges2/lusnazard (13).png";
import lusnazard01 from "../src/work.imges2/lusnazard2  (1).png";
import lusnazard02 from "../src/work.imges2/lusnazard2  (2).png";
import lusnazard03 from "../src/work.imges2/lusnazard2  (3).png";
import lusnazard04 from "../src/work.imges2/lusnazard2  (4).png";
import lusnazard05 from "../src/work.imges2/lusnazard2  (5).png";
import lusnazard06 from "../src/work.imges2/lusnazard2  (6).png";
import lusnazard07 from "../src/work.imges2/lusnazard2  (7).png";
import lusnazard08 from "../src/work.imges2/lusnazard2  (8).png";
import lusnazard09 from "../src/work.imges2/lusnazard2  (9).png";
import lusnazard010 from "../src/work.imges2/lusnazard2  (10).png";
import lusnazard011 from "../src/work.imges2/lusnazard2  (11).png";
import lusnazard012 from "../src/work.imges2/lusnazard2  (12).png";
import lusnazard013 from "../src/work.imges2/lusnazard2  (13).png";
import lusnazard014 from "../src/work.imges2/lusnazard2  (14).png";
import lusnazard015 from "../src/work.imges2/lusnazard2  (15).png";
import lusnazard016 from "../src/work.imges2/lusnazard2  (16).png";
import lusnazard017 from "../src/work.imges2/lusnazard3 (1).png";
import lusnazard018 from "../src/work.imges2/lusnazard3 (2).png";
import lusnazard019 from "../src/work.imges2/lusnazard3 (3).png";
import lusnazard020 from "../src/work.imges2/lusnazard3 (4).png";
import lusnazard021 from "../src/work.imges2/lusnazard3 (5).png";
import lusnazard022 from "../src/work.imges2/lusnazard3 (6).png";
import lusnazard023 from "../src/work.imges2/lusnazard3 (7).png";
import lusnazard024 from "../src/work.imges2/lusnazard3 (8).png";
import zooty1 from "../src/work.imges2/zooty (1).png";
import zooty2 from "../src/work.imges2/zooty (2).png";
import zooty3 from "../src/work.imges2/zooty (3).png";
import zooty4 from "../src/work.imges2/zooty (4).png";
import zooty5 from "../src/work.imges2/zooty (5).png";
import zooty6 from "../src/work.imges2/zooty (6).png";
import zooty7 from "../src/work.imges2/zooty (7).png";
import zooty8 from "../src/work.imges2/zooty (8).png";
import zooty9 from "../src/work.imges2/zooty (9).png";
import zooty10 from "../src/work.imges2/zooty (10).png";
import zooty11 from "../src/work.imges2/zooty (11).png";
import zooty12 from "../src/work.imges2/zooty2 (1).png";
import zooty13 from "../src/work.imges2/zooty2 (2).png";
import zooty14 from "../src/work.imges2/zooty2 (3).png";
import zooty15 from "../src/work.imges2/zooty2 (4).png";
import zooty16 from "../src/work.imges2/zooty2 (5).png";
import zooty17 from "../src/work.imges2/zooty2 (6).png";
import zooty18 from "../src/work.imges2/zooty2 (7).png";
import zooty19 from "../src/work.imges2/zooty2 (8).png";
import oxygen1 from "../src/work.imges2/Oxygen (1).png";
import oxygen2 from "../src/work.imges2/Oxygen (2).png";
import oxygen3 from "../src/work.imges2/Oxygen (3).png";
import oxygen4 from "../src/work.imges2/Oxygen (4).png";
import oxygen5 from "../src/work.imges2/Oxygen (5).png";
import oxygen6 from "../src/work.imges2/Oxygen (6).png";
import oxygen7 from "../src/work.imges2/Oxygen (7).png";
import oxygen8 from "../src/work.imges2/Oxygen (8).png";
import oxygen9 from "../src/work.imges2/Oxygen (9).png";
import oxygen10 from "../src/work.imges2/Oxygen (10).png";
import oxygen11 from "../src/work.imges2/Oxygen (11).png";
import oxygen12 from "../src/work.imges2/Oxygen (12).png";
import oxygen13 from "../src/work.imges2/Oxygen (13).png";
import oxygen14 from "../src/work.imges2/Oxygen (14).png";
import oxygen15 from "../src/work.imges2/Oxygen (15).png";
import CakeOclockBrand1 from "../src/work.imges2/CakeO'clockBrand (1).png";
import CakeOclockBrand2 from "../src/work.imges2/CakeO'clockBrand (2).png";
import CakeOclockBrand3 from "../src/work.imges2/CakeO'clockBrand (3).png";
import CakeOclockBrand4 from "../src/work.imges2/CakeO'clockBrand (4).png";
import CakeOclockBrand5 from "../src/work.imges2/CakeO'clockBrand (5).png";
import CakeOclockBrand6 from "../src/work.imges2/CakeO'clockBrand (6).png";
import CakeOclockBrand7 from "../src/work.imges2/CakeO'clockBrand (7).png";
import CakeOclockBrand8 from "../src/work.imges2/CakeO'clockBrand (8).png";
import CakeOclockBrand9 from "../src/work.imges2/CakeO'clockBrand (9).png";
import CakeOclockBrand10 from "../src/work.imges2/CakeO'clockBrand (10).png";
import CakeOclockBrand11 from "../src/work.imges2/CakeO'clockBrand (11).png";
import CakeOclockBrand12 from "../src/work.imges2/CakeO'clockBrand (12).png";
import glowing1 from "../src/work.imges2/glowing (1).png";
import glowing2 from "../src/work.imges2/glowing (2).png";
import glowing3 from "../src/work.imges2/glowing (3).png";
import glowing4 from "../src/work.imges2/glowing (4).png";
import glowing5 from "../src/work.imges2/glowing (5).png";
import glowing6 from "../src/work.imges2/glowing (6).png";
import glowing7 from "../src/work.imges2/glowing (7).png";
import glowing8 from "../src/work.imges2/glowing (8).png";
import bw1 from "../src/work.imges2/b&w (1).png";
import bw2 from "../src/work.imges2/b&w (2).png";
import bw3 from "../src/work.imges2/b&w (3).png";
import bw4 from "../src/work.imges2/b&w (4).png";
import bw5 from "../src/work.imges2/b&w (5).png";
import bw6 from "../src/work.imges2/b&w (6).png";
import bw7 from "../src/work.imges2/b&w (7).png";
import bw8 from "../src/work.imges2/b&w (8).png";
import poster1 from "../src/work.imges2/poster (1).png"
import poster2 from "../src/work.imges2/poster (2).png"
import poster3 from "../src/work.imges2/poster (3).png"
import poster4 from "../src/work.imges2/poster (4).png"
import photoShop1 from "../src/work.imges2/photoshop (1).png"
import photoShop2 from "../src/work.imges2/photoshop (2).png"
import photoShop3 from "../src/work.imges2/photoshop (3).png"
import photoShop4 from "../src/work.imges2/photoshop (4).png"
 import posters1 from "../src/work.imges2/post_1 (1).png"
 import posters2 from "../src/work.imges2/post_1 (2).png"
 import posters3 from "../src/work.imges2/post_1 (3).png"
 import posters4 from "../src/work.imges2/post_1 (4).png"
 import posters5 from "../src/work.imges2/post_1 (5).png"
  import  calipos1 from "../src/work.imges2/calipso (1).jpg"
  import  calipos2 from "../src/work.imges2/calipso (2).jpg"
  import  calipos3 from "../src/work.imges2/calipso (3).jpg"
  import  calipos4 from "../src/work.imges2/calipso (4).jpg"
  import  calipos5 from "../src/work.imges2/calipso (5).jpg"
  import  calipos6 from "../src/work.imges2/calipso (6).jpg"
  import  calipos7 from "../src/work.imges2/calipso (7).jpg"
  import  calipos8 from "../src/work.imges2/calipso (8).jpg"
  import  calipos9 from "../src/work.imges2/calipso (9).jpg"
  import  calipos10 from "../src/work.imges2/calipso (10).jpg"
  import  photo1 from '../src/work.imges2/photo1 (1).jpg'
  import  photo2 from '../src/work.imges2/photo1 (2).jpg'
  import  photo3 from '../src/work.imges2/photo1 (3).jpg'
  import  photo4 from '../src/work.imges2/photo1 (4).jpg'
  import  photo5 from '../src/work.imges2/photo1 (5).jpg'
  import  photo6 from '../src/work.imges2/photo1 (6).jpg'
   import Artsakh1 from '../src/work.imges2/Artsakh (1).png'
   import Artsakh2 from '../src/work.imges2/Artsakh (2).png'
   import Artsakh3 from '../src/work.imges2/Artsakh (3).png'
   import Artsakh4 from '../src/work.imges2/Artsakh (4).png'
  //  import Artsakh5 from '../src/work.imges2/Artsakh (5).png'
   import Artsakh6 from '../src/work.imges2/Artsakh (6).png'
   import Artsakh7 from '../src/work.imges2/Artsakh (7).png'
   import Artsakh8 from '../src/work.imges2/Artsakh (8).png'
   import Artsakh9 from '../src/work.imges2/Artsakh (9).png'
  import film1 from "../src/work.imges2/photo2 (1).png"
  import film2 from "../src/work.imges2/photo2 (2).png"
  import film3 from "../src/work.imges2/photo2 (3).png"
  import film4 from "../src/work.imges2/photo2 (4).png"
  import film5 from "../src/work.imges2/photo2 (5).png"
  import film6 from "../src/work.imges2/photo2 (6).png"
  import film7 from "../src/work.imges2/photo2 (7).png"
   import quant1 from '../src/work.imges2/quant (1).jpg'
   import quant2 from '../src/work.imges2/quant (2).jpg'
   import quant3 from '../src/work.imges2/quant (3).jpg'
   import quant4 from '../src/work.imges2/quant (4).jpg'
   import quant5 from '../src/work.imges2/quant (5).jpg'
   import quant6 from '../src/work.imges2/quant (6).jpg'
   import quant7 from '../src/work.imges2/quant (7).jpg'
   import quant8 from '../src/work.imges2/quant (8).jpg'
   import quant9 from '../src/work.imges2/quant (9).jpg'
   import quant10 from '../src/work.imges2/quant (10).jpg'
   import quant11 from '../src/work.imges2/quant (11).jpg'
   import quant12 from '../src/work.imges2/quant (12).jpg'
   import quant13 from '../src/work.imges2/quant (13).jpg'
   import quant14 from '../src/work.imges2/quant (14).jpg'
   import quant15 from '../src/work.imges2/quant (15).jpg'
   import quant16 from '../src/work.imges2/quant (16).jpg'
   import quant17 from '../src/work.imges2/quant (17).jpg'
   import quant18 from '../src/work.imges2/quant (18).jpg'
   import quant19 from '../src/work.imges2/quant (19).jpg'
   import quant20 from '../src/work.imges2/quant (20).jpg'
   import quant21 from '../src/work.imges2/quant (21).jpg'
   import quant22 from '../src/work.imges2/quant (22).jpg'
   import quant23 from '../src/work.imges2/quant (23).jpg'
   import quant24 from '../src/work.imges2/quant (24).jpg'
   import quant25 from '../src/work.imges2/quant (25).jpg'
   import quant26 from '../src/work.imges2/quant (26).jpg'
   import quant27 from '../src/work.imges2/quant (27).jpg'
   import quant28 from '../src/work.imges2/quant (28).jpg'
   import quant29 from '../src/work.imges2/quant (29).jpg'
    import quant30 from '../src/work.imges2/quant (1).png'
    import quant31 from '../src/work.imges2/quant (2).png'
    import quant32 from '../src/work.imges2/quant (3).png'
    import quant33 from '../src/work.imges2/quant (4).png'
    import quant34 from '../src/work.imges2/quant (5).png'
    import men1  from "../src/work.imges2/photomen (1).jpg"
    import men2  from "../src/work.imges2/photomen (2).jpg"
    import men3  from "../src/work.imges2/photomen (3).jpg"
    import men4  from "../src/work.imges2/photomen (4).jpg"
    import men5  from "../src/work.imges2/photomen (5).jpg"
     import imges1 from '../src/work.imges2/photo4 (1).png'
     import imges2 from '../src/work.imges2/photo4 (2).png'
     import imges3 from '../src/work.imges2/photo4 (3).png'
     import imges4 from '../src/work.imges2/photo4 (4).png'
     import imges5 from '../src/work.imges2/photo4 (5).png'
     import imges6 from '../src/work.imges2/photo4 (6).png'
     import imges7 from '../src/work.imges2/photo4 (7).png'
     import imges8 from '../src/work.imges2/photo4 (8).png'
     import imges9 from '../src/work.imges2/photo4 (9).png'
     import imges10 from '../src/work.imges2/photo4 (10).png'
     import imges11 from '../src/work.imges2/photo4 (11).png'
     import imges12 from '../src/work.imges2/photo4 (12).png'
     import imges13 from '../src/work.imges2/photo4 (13).png'
     import imges14 from '../src/work.imges2/photo4 (14).png'
     import imges15 from '../src/work.imges2/photo4 (15).png'
     import imges16 from '../src/work.imges2/photo4 (16).png'
     import imges17 from '../src/work.imges2/photo4 (17).png'
     import imges18 from '../src/work.imges2/photo4 (18).png'
     import imges19 from '../src/work.imges2/photo4 (19).png'
     import imges20 from '../src/work.imges2/photo4 (20).png'
     import imges21 from '../src/work.imges2/photo4 (21).png'
      import img1 from '../src/work.imges2/1.jpg'
      import img2 from '../src/work.imges2/2.jpg'
      import img3 from '../src/work.imges2/3.jpg'
      import img4 from '../src/work.imges2/4.jpg'
      import img5 from '../src/work.imges2/5.jpg'
      import img6 from '../src/work.imges2/6.jpg'

export const luminis = [
  { 
    original: luminis1,
    thumbnail: luminis1
  },
  { 
    original: luminis2,
    thumbnail: luminis2,
  },
  { 
    original: luminis3,
    thumbnail: luminis3,
  },
  { 
    original: luminis4,
    thumbnail: luminis4,
  },
  { 
    original: luminis5,
    thumbnail: luminis5,
  },
  { 
    original: luminis6,
    thumbnail: luminis6,
  },
  { 
    original: luminis7,
    thumbnail: luminis7,
  },
 
  { 
    original: luminis9,
    thumbnail: luminis9,
  },
  { 
    original: luminis10,
    thumbnail: luminis10,
  },
  { 
    original: luminis11,
    thumbnail: luminis11,
  },
  { 
    original: luminis13,
    thumbnail: luminis13,
  },
  { 
    original: luminis14,
    thumbnail: luminis14,
  },
  { 
    original: luminis15,
    thumbnail: luminis15,
  },
  { 
    original: luminis16,
    thumbnail: luminis16,
  },
  { 
    original: luminis17,
    thumbnail: luminis17,
  },
  { 
    original: luminis18,
    thumbnail: luminis18,
  },
  { 
    original: luminis19,
    thumbnail: luminis19,
  },
  { 
    original: luminis20,
    thumbnail: luminis20,
  },
  { 
    original: luminis21,
    thumbnail: luminis21,
  },
  { 
    original: luminis22,
    thumbnail: luminis22,
  },
  { 
    original: luminis23,
    thumbnail: luminis23,
  },
];

export const buyr = [
  { 
    original: buyr1,
    thumbnail: buyr1
  },
  { 
    original: buyr2,
    thumbnail: buyr2
  },
  { 
    original: buyr3,
    thumbnail: buyr3
  },
  { 
    original: buyr4,
    thumbnail: buyr4
  },
  { 
    original: buyr5,
    thumbnail: buyr5
  },
  { 
    original: buyr6,
    thumbnail: buyr6
  },
  { 
    original: buyr7,
    thumbnail: buyr7
  },
  { 
    original: buyr8,
    thumbnail: buyr8
  },
  { 
    original: buyr9,
    thumbnail: buyr9
  },
  { 
    original: buyr10,
    thumbnail: buyr10
  },
  { 
    original: buyr11,
    thumbnail: buyr11
  },
  { 
    original: buyr12,
    thumbnail: buyr12
  },
  { 
    original: buyr13,
    thumbnail: buyr13
  },
  { 
    original: buyr14,
    thumbnail: buyr14
  },
  { 
    original: buyr15,
    thumbnail: buyr15
  },
];

export const glasseOf = [
  { 
    original: glassof1,
    thumbnail: glassof1
  },
  { 
    original: glassof2,
    thumbnail: glassof2
  },
  { 
    original: glassof3,
    thumbnail: glassof3
  },
  { 
    original: glassof4,
    thumbnail:glassof4
  },
  { 
    original: glassof5,
    thumbnail:glassof5
  },
  { 
    original: glassof6,
    thumbnail:glassof6
  },
  { 
    original: glassof7,
    thumbnail:glassof7
  },
  { 
    original: glassof8,
    thumbnail:glassof8
  },
  { 
    original: glassof9,
    thumbnail:glassof9
  },
 
  { 
    original: glassof10,
    thumbnail:glassof10
  },
  { 
    original: glassof11,
    thumbnail:glassof11
  },
  { 
    original: glassof12,
    thumbnail:glassof12
  },
  { 
    original: glassof13,
    thumbnail:glassof13
  },
  { 
    original: glassof14,
    thumbnail:glassof14
  },
  { 
    original: glassof15,
    thumbnail:glassof15
  },
  { 
    original: glassof16,
    thumbnail:glassof16
  },
  
];

export const poeme = [
  { 
    original: poeme1,
    thumbnail:poeme1
  },
  { 
    original: poeme2,
    thumbnail:poeme2
  },
  { 
    original: poeme3,
    thumbnail:poeme3
  },
  { 
    original: poeme4,
    thumbnail:poeme4
  },
  { 
    original: poeme5,
    thumbnail:poeme5
  },
  { 
    original: poeme6,
    thumbnail:poeme6
  },
  { 
    original: poeme7,
    thumbnail:poeme7
  },
  { 
    original: poeme8,
    thumbnail:poeme8
  },
  { 
    original: poeme9,
    thumbnail:poeme9
  },
  { 
    original: poeme10,
    thumbnail:poeme10
  },
  { 
    original: poeme11,
    thumbnail:poeme11
  },
  { 
    original: poeme12,
    thumbnail:poeme12
  },
  { 
    original: poeme13,
    thumbnail:poeme13
  },
  { 
    original: poeme14,
    thumbnail:poeme14
  },
  { 
    original: poeme15,
    thumbnail:poeme15
  },
  { 
    original: poeme16,
    thumbnail:poeme16
  },
  { 
    original: poeme17,
    thumbnail:poeme17
  },
  { 
    original: poeme18,
    thumbnail:poeme18
  },
  { 
    original: poeme19,
    thumbnail:poeme19
  },
 
];

export const ofus = [
  { 
    original: ofus1,
    thumbnail:ofus1
  },
  { 
    original: ofus2,
    thumbnail:ofus2
  },
  { 
    original: ofus3,
    thumbnail:ofus3
  },
  { 
    original: ofus4,
    thumbnail:ofus4
  },
  { 
    original:ofus5,
    thumbnail:ofus5
  },
  { 
    original: ofus6,
    thumbnail:ofus6
  },
  { 
    original: ofus7,
    thumbnail:ofus7
  },
  { 
    original: ofus8,
    thumbnail:ofus8
  },
  { 
    original: ofus9,
    thumbnail:ofus9
  },
  { 
    original: ofus10,
    thumbnail:ofus10
  },
  { 
    original: ofus11,
    thumbnail:ofus11
  },
  { 
    original: ofus12,
    thumbnail:ofus12
  },
  { 
    original: ofus13,
    thumbnail:ofus13
  },
  { 
    original: ofus14,
    thumbnail:ofus14
  },
  { 
    original: ofus15,
    thumbnail:ofus15
  },
  { 
    original: ofus16,
    thumbnail:ofus16
  },
  { 
    original: ofus17,
    thumbnail:ofus17
  },
  { 
    original: ofus18,
    thumbnail:ofus18
  },
  { 
    original: ofus19,
    thumbnail:ofus19
  },
  { 
    original: ofus20,
    thumbnail:ofus20
  },
  { 
    original: ofus21,
    thumbnail:ofus21
  },
  
];

export const greenZone = [
  { 
    original: green1,
    thumbnail:green1
  },
  { 
    original: green2,
    thumbnail:green2
  },
  { 
    original: green3,
    thumbnail:green3
  },
  { 
    original: green4,
    thumbnail:green4
  },
  { 
    original: green5,
    thumbnail:green5
  },
  { 
    original: green6,
    thumbnail:green6
  },
  { 
    original: green7,
    thumbnail:green7
  },
  { 
    original: green8,
    thumbnail:green8
  },
  { 
    original: green9,
    thumbnail:green9
  },
  { 
    original: green10,
    thumbnail:green10
  },
  { 
    original: green11,
    thumbnail:green11
  },
  { 
    original: green10,
    thumbnail:green10
  },
  { 
    original: green12,
    thumbnail:green12
  },
  { 
    original: green13,
    thumbnail:green13
  },
  { 
    original: green14,
    thumbnail:green14
  },
  { 
    original: green15,
    thumbnail:green15
  },
  { 
    original: green16,
    thumbnail:green16
  },
  { 
    original: green17,
    thumbnail:green17
  },
  { 
    original: green18,
    thumbnail:green18
  },
  { 
    original: green19,
    thumbnail:green19
  },
  { 
    original: green20,
    thumbnail:green20
  },
  { 
    original: green21,
    thumbnail:green21
  },
  { 
    original: green22,
    thumbnail:green22
  },
  { 
    original: green23,
    thumbnail:green23
  },
  { 
    original: green24,
    thumbnail:green24
  },
  { 
    original: green25,
    thumbnail:green25
  },
  { 
    original: green26,
    thumbnail:green26
  },
  { 
    original: green27,
    thumbnail:green27
  },
  { 
    original: green28,
    thumbnail:green28
  },
  { 
    original: green29,
    thumbnail:green29
  },
  { 
    original: green30,
    thumbnail:green30
  },
  { 
    original: green31,
    thumbnail:green31
  },
  { 
    original: green32,
    thumbnail:green32
  },
  { 
    original: green33,
    thumbnail:green33
  },

  { 
    original: green34,
    thumbnail:green34
  },
  { 
    original: green35,
    thumbnail:green35
  },
  { 
    original:green36 ,
    thumbnail:green36
  },
  { 
    original:green37 ,
    thumbnail:green37
  },
  { 
    original:green37 ,
    thumbnail:green37
  },
  { 
    original:green38 ,
    thumbnail:green38
  },
  { 
    original:green39 ,
    thumbnail:green39
  },
  { 
    original:green40 ,
    thumbnail:green40
  },
  { 
    original:green41 ,
    thumbnail:green41
  },
  { 
    original:green42 ,
    thumbnail:green42
  },
  { 
    original:green43 ,
    thumbnail:green43
  },
  { 
    original:green44 ,
    thumbnail:green44
  },
  { 
    original:green45 ,
    thumbnail:green45
  },
  { 
    original:green46 ,
    thumbnail:green46
  },
  { 
    original:green47 ,
    thumbnail:green47
  },
  { 
    original:green48 ,
    thumbnail:green48
  },
  { 
    original:green49 ,
    thumbnail:green49
  },
  { 
    original:green50 ,
    thumbnail:green50
  },
  { 
    original:green51 ,
    thumbnail:green51
  }
];

export const hoode = [
  { 
    original:hoode1 ,
    thumbnail:hoode1
  },
  { 
    original:hoode2 ,
    thumbnail:hoode2
  },
  { 
    original:hoode3 ,
    thumbnail:hoode3
  },
  { 
    original:hoode$2  ,
    thumbnail:hoode$2 
  },
  { 
    original:hoode5 ,
    thumbnail:hoode5
  },
  { 
    original:hoode6 ,
    thumbnail:hoode6
  },
  { 
    original:hoode7 ,
    thumbnail:hoode7
  },
  { 
    original:hoode8 ,
    thumbnail:hoode8
  },
  { 
    original:hoode9 ,
    thumbnail:hoode9
  },
  { 
    original:hoode10 ,
    thumbnail:hoode10
  },
  { 
    original:hoode11 ,
    thumbnail:hoode11
  },
  { 
    original:hoode12 ,
    thumbnail:hoode12
  },
  { 
    original:hoode13 ,
    thumbnail:hoode13
  },
  { 
    original:hoode14,
    thumbnail:hoode14
  },
  { 
    original:hoode15 ,
    thumbnail:hoode15
  },
  { 
    original:hoode16 ,
    thumbnail:hoode16
  },
  { 
    original:hoode17 ,
    thumbnail:hoode17
  },
  { 
    original:hoode18 ,
    thumbnail:hoode18
  },
  { 
    original:hoode19 ,
    thumbnail:hoode19
  },
  { 
    original:hoode20 ,
    thumbnail:hoode20
  },
  { 
    original:hoode21 ,
    thumbnail:hoode21
  },
  { 
    original:hoode22 ,
    thumbnail:hoode22
  },
  { 
    original:hoode22 ,
    thumbnail:hoode22
  },
  { 
    original:hoode23 ,
    thumbnail:hoode23
  },
  { 
    original:hoode24 ,
    thumbnail:hoode24
  },
  { 
    original:hoode25 ,
    thumbnail:hoode25
  },
  { 
    original:hoode26 ,
    thumbnail:hoode26
  },
 
];

export const lolli = [
  { 
    original:lolli1 ,
    thumbnail:lolli1
  },
  { 
    original:lolli2 ,
    thumbnail:lolli2
  },
  { 
    original:lolli3 ,
    thumbnail:lolli3
  },
  { 
    original:lolli4 ,
    thumbnail:lolli4
  },
  { 
    original:lolli5 ,
    thumbnail:lolli5
  },
  { 
    original:lolli6 ,
    thumbnail:lolli6
  },
  { 
    original:lolli7 ,
    thumbnail:lolli7
  },
  { 
    original:lolli8 ,
    thumbnail:lolli8
  },
  { 
    original:lolli9 , 
    thumbnail:lolli9
  },
  { 
    original:lolli10 ,
    thumbnail:lolli10
  },
  { 
    original:lolli11 ,
    thumbnail:lolli11
  },
  { 
    original:lolli12 ,
    thumbnail:lolli12
  },
  { 
    original:lolli13 ,
    thumbnail:lolli13
  },
  { 
    original:lolli14 ,
    thumbnail:lolli14
  },
  { 
    original:lolli15 ,
    thumbnail:lolli15
  },
  { 
    original:lolli16 ,
    thumbnail:lolli16
  },
  
];

export const mnadarin = [
  { 
    original:mandarin1 ,
    thumbnail:mandarin1
  },
 
  { 
    original:mandarin2 ,
    thumbnail:mandarin2
  },
  { 
    original:mandarin2 ,
    thumbnail:mandarin2
  },
  { 
    original:mandarin3 ,
    thumbnail:mandarin3
  },
  { 
    original:mandarin4 ,
    thumbnail:mandarin4
  },
  { 
    original:mandarin4 ,
    thumbnail:mandarin4
  },
  { 
    original:mandarin5 ,
    thumbnail:mandarin5
  },
  { 
    original:mandarin6 ,
    thumbnail:mandarin6
  },
  { 
    original:mandarin7 ,
    thumbnail:mandarin7
  },
  { 
    original:mandarin8 ,
    thumbnail:mandarin8
  },
  { 
    original:mandarin9 ,
    thumbnail:mandarin9
  },
  { 
    original:mandarin10 ,
    thumbnail:mandarin10
  },
  { 
    original:mandarin11 ,
    thumbnail:mandarin11
  },
  { 
    original:mandarin12 ,
    thumbnail:mandarin12
  },
  { 
    original:mandarin13 ,
    thumbnail:mandarin13
  },
  { 
    original:mandarin14 ,
    thumbnail:mandarin14
  },
  { 
    original:mandarin15 ,
    thumbnail:mandarin15
  },
  { 
    original:mandarin16 ,
    thumbnail:mandarin16
  },
  { 
    original:mandarin17 ,
    thumbnail:mandarin17
  },
  { 
    original:mandarin18 ,
    thumbnail:mandarin18
  }
  
];

export const meliqsetyans = [
  { 
    original:meliqsetyans1 ,
    thumbnail:meliqsetyans1
  },
  { 
    original:meliqsetyans2 ,
    thumbnail:meliqsetyans2
  },
  { 
    original:meliqsetyans3 ,
    thumbnail:meliqsetyans3
  },
  { 
    original:meliqsetyans4 ,
    thumbnail:meliqsetyans4
  },
  { 
    original:meliqsetyans5 ,
    thumbnail:meliqsetyans5
  },
  { 
    original:meliqsetyans6 ,
    thumbnail:meliqsetyans6
  },
  { 
    original:meliqsetyans7 ,
    thumbnail:meliqsetyans7
  },
  { 
    original:meliqsetyans8 ,
    thumbnail:meliqsetyans8
  },
  { 
    original:meliqsetyans9 ,
    thumbnail:meliqsetyans9
  },
  { 
    original:meliqsetyans10 ,
    thumbnail:meliqsetyans10
  },
  { 
    original:meliqsetyans11 ,
    thumbnail:meliqsetyans11
  },
  { 
    original:meliqsetyans12 ,
    thumbnail:meliqsetyans12
  },
  { 
    original:meliqsetyans13 ,
    thumbnail:meliqsetyans13
  },
  { 
    original:meliqsetyans13 ,
    thumbnail:meliqsetyans13
  },
  { 
    original:meliqsetyans14 ,
    thumbnail:meliqsetyans14
  },
  { 
    original:meliqsetyans15 ,
    thumbnail:meliqsetyans15
  },
  { 
    original:meliqsetyans16 ,
    thumbnail:meliqsetyans16
  },
  { 
    original:meliqsetyans17 ,
    thumbnail:meliqsetyans17
  },
  { 
    original:meliqsetyans18 ,
    thumbnail:meliqsetyans18
  },
  { 
    original:meliqsetyans19 ,
    thumbnail:meliqsetyans19
  },
  { 
    original:meliqsetyans20 ,
    thumbnail:meliqsetyans20
  }
 
];

export const giftSop = [
  { 
    original:giftSop1 ,
    thumbnail:giftSop1
  },
  { 
    original:giftSop2 ,
    thumbnail:giftSop2
  },
  { 
    original:giftSop2 ,
    thumbnail:giftSop2
  },
  { 
    original:giftSop3 ,
    thumbnail:giftSop3
  },
  { 
    original:giftSop4 ,
    thumbnail:giftSop4
  },
  { 
    original:giftSop5 ,
    thumbnail:giftSop5
  },
  { 
    original:giftSop6 ,
    thumbnail:giftSop6
  },
  { 
    original:giftSop7 ,
    thumbnail:giftSop7
  },
  { 
    original:giftSop8 ,
    thumbnail:giftSop8
  },
  { 
    original:giftSop9 ,
    thumbnail:giftSop9
  },
  { 
    original:giftSop10 ,
    thumbnail:giftSop10
  },
  { 
    original:giftSop11 ,
    thumbnail:giftSop11
  },
  {
    original:giftSop12,
    thumbnail:giftSop12
  },
  { 
    original:giftSop13 ,
    thumbnail:giftSop13
  },
  { 
    original:giftSop14 ,
    thumbnail:giftSop14
  },
  { 
    original:giftSop15 ,
    thumbnail:giftSop15
  },
  { 
    original:giftSop16 ,
    thumbnail:giftSop16
  },
  { 
    original:giftSop17 ,
    thumbnail:giftSop17
  },
  { 
    original:giftSop18 ,
    thumbnail:giftSop18
  },
  { 
    original:giftSop19 ,
    thumbnail:giftSop19
  }
];

export const donut = [
  { 
    original:donut1 ,
    thumbnail:donut1
  },
  { 
    original:donut2 ,
    thumbnail:donut2
  },
  { 
    original:donut3 ,
    thumbnail:donut3
  },
  { 
    original:donut4 ,
    thumbnail:donut4
  },
  { 
    original:donut5 ,
    thumbnail:donut5
  },
  { 
    original:donut6 ,
    thumbnail:donut6
  },
  { 
    original:donut7 ,
    thumbnail:donut7
  }, 
  { 
    original:donut8 ,
    thumbnail:donut8
  },
  { 
    original:donut9 ,
    thumbnail:donut9
  },
  { 
    original:donut10 ,
    thumbnail:donut10
  },
  { 
    original:donut11 ,
    thumbnail:donut11
  },
  { 
    original:donut12 ,
    thumbnail:donut12
  },
  { 
    original:donut13 ,
    thumbnail:donut13
  },
  { 
    original:donut14 ,
    thumbnail:donut14
  },
  { 
    original:donut15 ,
    thumbnail:donut15
  },
  { 
    original:donut16 ,
    thumbnail:donut16
  },
 
];

export const lusnazard = [
  { 
  original:lusnazard1 ,
  thumbnail:lusnazard1
},
{ 
  original:lusnazard2 ,
  thumbnail:lusnazard2
},
{ 
  original:lusnazard3 ,
  thumbnail:lusnazard3
},
{ 
  original:lusnazard4 ,
  thumbnail:lusnazard4
},
{ 
  original:lusnazard5 ,
  thumbnail:lusnazard5
},
{ 
  original:lusnazard6 ,
  thumbnail:lusnazard6
},
{ 
  original:lusnazard7 ,
  thumbnail:lusnazard7
},
{ 
  original:lusnazard8 ,
  thumbnail:lusnazard8
},
{ 
  original:lusnazard9 ,
  thumbnail:lusnazard9
},
{ 
  original:lusnazard001 ,
  thumbnail:lusnazard001
},
{ 
  original:lusnazard11 ,
  thumbnail:lusnazard11
},
{ 
  original:lusnazard12 ,
  thumbnail:lusnazard12
},
{ 
  original:lusnazard13 ,
  thumbnail:lusnazard13
},
{ 
  original:lusnazard01 ,
  thumbnail:lusnazard01
},
{ 
  original:lusnazard02 ,
  thumbnail:lusnazard02
},
{ 
  original:lusnazard03 ,
  thumbnail:lusnazard03
},
{ 
  original:lusnazard04 ,
  thumbnail:lusnazard04
},
{ 
  original:lusnazard05 ,
  thumbnail:lusnazard05
},
{ 
  original:lusnazard06 ,
  thumbnail:lusnazard06
},
{ 
  original:lusnazard07 ,
  thumbnail:lusnazard07
},
{ 
  original:lusnazard08 ,
  thumbnail:lusnazard08
},
{ 
  original:lusnazard09 ,
  thumbnail:lusnazard09
},
{ 
  original:lusnazard010 ,
  thumbnail:lusnazard010
},
{ 
  original:lusnazard011 ,
  thumbnail:lusnazard011
},
{ 
  original:lusnazard012 ,
  thumbnail:lusnazard012
},
{ 
  original:lusnazard013 ,
  thumbnail:lusnazard013
},
{ 
  original:lusnazard014 ,
  thumbnail:lusnazard014
},
{ 
  original:lusnazard015 ,
  thumbnail:lusnazard015
},
{ 
  original:lusnazard016 ,
  thumbnail:lusnazard016
},
{ 
  original:lusnazard017 ,
  thumbnail:lusnazard017
},
{ 
  original:lusnazard018 ,
  thumbnail:lusnazard018
},
{ 
  original:lusnazard019 ,
  thumbnail:lusnazard019
},
{ 
  original:lusnazard020 ,
  thumbnail:lusnazard020
},
{ 
  original:lusnazard021 ,
  thumbnail:lusnazard021
},
{ 
  original:lusnazard022 ,
  thumbnail:lusnazard022
},
{ 
  original:lusnazard023 ,
  thumbnail:lusnazard023
},
{ 
  original:lusnazard024 ,
  thumbnail:lusnazard024
},
  
];

export const zooty = [
  { 
    original:zooty1 ,
    thumbnail:zooty1
  },
  { 
    original:zooty2 ,
    thumbnail:zooty2
  },
  { 
    original:zooty3 ,
    thumbnail:zooty3
  },
  { 
    original:zooty4 ,
    thumbnail:zooty4
  },
  { 
    original:zooty5 ,
    thumbnail:zooty5
  },
  { 
    original:zooty6 ,
    thumbnail:zooty6
  },
  { 
    original:zooty7 ,
    thumbnail:zooty7
  },
  { 
    original:zooty8 ,
    thumbnail:zooty8
  },
  { 
    original:zooty9 ,
    thumbnail:zooty9
  },
  { 
    original:zooty10 ,
    thumbnail:zooty10
  },
  { 
    original:zooty11 ,
    thumbnail:zooty11
  },
  { 
    original:zooty11 ,
    thumbnail:zooty11
  },
  { 
    original:zooty12 ,
    thumbnail:zooty12
  },
  { 
    original:zooty13 ,
    thumbnail:zooty13
  },
  { 
    original:zooty14 ,
    thumbnail:zooty14
  },
  { 
    original:zooty15 ,
    thumbnail:zooty15
  },
  { 
    original:zooty16 ,
    thumbnail:zooty16
  },
  { 
    original:zooty17 ,
    thumbnail:zooty17
  },
  { 
    original:zooty18 ,
    thumbnail:zooty18
  },
  { 
    original:zooty19 ,
    thumbnail:zooty19
  },
  
];

export const oxygen = [
  { 
    original:oxygen1 ,
    thumbnail:oxygen1
  },
  { 
    original:oxygen2 ,
    thumbnail:oxygen2
  },
  { 
    original:oxygen2 ,
    thumbnail:oxygen2
  },
  { 
    original:oxygen3 ,
    thumbnail:oxygen3
  },
  { 
    original:oxygen4 ,
    thumbnail:oxygen4
  },
  { 
    original:oxygen5 ,
    thumbnail:oxygen5
  },
  { 
    original:oxygen6 ,
    thumbnail:oxygen6
  },
  { 
    original:oxygen7 ,
    thumbnail:oxygen7
  },
  { 
    original:oxygen8,
    thumbnail:oxygen8  
  },
  { 
    original:oxygen9 ,
    thumbnail:oxygen9
  },
  { 
    original:oxygen10 ,
    thumbnail:oxygen10
  },
  { 
    original:oxygen11 ,
    thumbnail:oxygen11
  },
  { 
    original:oxygen12 ,
    thumbnail:oxygen12
  },
  { 
    original:oxygen12 ,
    thumbnail:oxygen12
  },
  { 
    original:oxygen13 ,
    thumbnail:oxygen13
  },
  { 
    original:oxygen14 ,
    thumbnail:oxygen14
  },
  { 
    original:oxygen15 ,
    thumbnail:oxygen15
  },
  
];

export const cakeOclockBrand1 = [
  
  { 
    original:CakeOclockBrand1 ,
    thumbnail:CakeOclockBrand1
  },
  { 
    original:CakeOclockBrand2 ,
    thumbnail:CakeOclockBrand2
  },
  { 
    original:CakeOclockBrand3 ,
    thumbnail:CakeOclockBrand3
  },
  { 
    original:CakeOclockBrand4 ,
    thumbnail:CakeOclockBrand4
  },
  { 
    original:CakeOclockBrand5 ,
    thumbnail:CakeOclockBrand5
  },
  { 
    original:CakeOclockBrand6 ,
    thumbnail:CakeOclockBrand6
  },
  { 
    original:CakeOclockBrand7 ,
    thumbnail:CakeOclockBrand7
  },
  { 
    original:CakeOclockBrand8 ,
    thumbnail:CakeOclockBrand8
  },
  { 
    original:CakeOclockBrand9 ,
    thumbnail:CakeOclockBrand9
  },
  { 
    original:CakeOclockBrand10 ,
    thumbnail:CakeOclockBrand10
  },
  { 
    original:CakeOclockBrand11 ,
    thumbnail:CakeOclockBrand11
  },
  { 
    original:CakeOclockBrand12 ,
    thumbnail:CakeOclockBrand12
  }
];

export const glowing = [
  { 
    original:glowing1 ,
    thumbnail:glowing1
  },
  { 
    original:glowing2 ,
    thumbnail:glowing2
  },
  { 
    original:glowing3 ,
    thumbnail:glowing3
  },
  { 
    original:glowing4 ,
    thumbnail:glowing4
  },
  { 
    original:glowing5 ,
    thumbnail:glowing5
  },
  { 
    original:glowing6 ,
    thumbnail:glowing6
  },
  { 
    original:glowing7 ,
    thumbnail:glowing7
  },
  { 
    original:glowing8 ,
    thumbnail:glowing8
  }
];

export const bw = [
  { 
    original:bw1 ,
    thumbnail:bw1
  },
  { 
    original:bw2 ,
    thumbnail:bw2
  },
  { 
    original:bw3 ,
    thumbnail:bw3
  },
  { 
    original:bw4 ,
    thumbnail:bw4
  },
  { 
    original:bw5 ,
    thumbnail:bw5
  },
  { 
    original:bw6 ,
    thumbnail:bw6
  },
  { 
    original:bw7 ,
    thumbnail:bw7
  },
  { 
    original:bw8 ,
    thumbnail:bw8
  },
];


export const poster = [
  { 
    original:poster1 ,
    thumbnail:poster1
  },
  { 
    original:poster2 ,
    thumbnail:poster2
  },
  { 
    original:poster3 ,
    thumbnail:poster3
  },
  { 
    original:poster4 ,
    thumbnail:poster4
  }
]

export const photoshop = [
  { 
    original:photoShop1 ,
    thumbnail:photoShop1
  },
  { 
    original:photoShop2 ,
    thumbnail:photoShop2
  },
  { 
    original:photoShop3 ,
    thumbnail:photoShop3
  },
  { 
    original:photoShop4 ,
    thumbnail:photoShop4
  },
 
   
]

export const posters = [
  { 
    original:posters1,
    thumbnail:posters1
  },
  { 
    original:posters2,
    thumbnail:posters2
  },
  { 
    original:posters3 ,
    thumbnail:posters3
  },
  { 
    original:posters4,
    thumbnail:posters4
  },
  { 
    original:posters5,
    thumbnail:posters5
  },
  

]

export  const  calipso = [
  { 
    original:calipos1,
    thumbnail:calipos1
  },
  { 
    original:calipos2,
    thumbnail:calipos2
  },
  { 
    original:calipos3,
    thumbnail:calipos3
  },
  { 
    original:calipos4,
    thumbnail:calipos4
  },
  { 
    original:calipos5,
    thumbnail:calipos5
  },
  { 
    original:calipos6,
    thumbnail:calipos6
  },
  { 
    original:calipos7,
    thumbnail:calipos7
  },
  { 
    original:calipos8,
    thumbnail:calipos8
  },
  { 
    original:calipos9,
    thumbnail:calipos9
  },
  { 
    original:calipos10,
    thumbnail:calipos10
  },
  { 
    original:calipos10,
    thumbnail:calipos10
  }
  
    
] 

export const photo = [
  { 
    original:photo1,
    thumbnail:photo1
  },
  { 
    original:photo2,
    thumbnail:photo2
  },
  { 
    original:photo1,
    thumbnail:photo1
  },
  { 
    original:photo3,
    thumbnail:photo3
  },
  { 
    original:photo4,
    thumbnail:photo4
  },
  { 
    original:photo5,
    thumbnail:photo5
  },
  { 
    original:photo6,
    thumbnail:photo6
  }
]

export const artsakh = [
  { 
    original:Artsakh1,
    thumbnail:Artsakh1
  },
  { 
    original:Artsakh2,
    thumbnail:Artsakh2
  },
  { 
    original:Artsakh3,
    thumbnail:Artsakh3
  },
  { 
    original:Artsakh4,
    thumbnail:Artsakh4
  },
  { 
    original:Artsakh6,
    thumbnail:Artsakh6
  },
  { 
    original:Artsakh7,
    thumbnail:Artsakh7
  },
  { 
    original:Artsakh8,
    thumbnail:Artsakh8
  },
  { 
    original:Artsakh9,
    thumbnail:Artsakh9
  },
  
]

export const film = [
  { 
    original:film1,
    thumbnail:film1
  },
  { 
    original:film2,
    thumbnail:film2
  },
  { 
    original:film3,
    thumbnail:film3
  },
  { 
    original:film4,
    thumbnail:film4
  },
  { 
    original:film5,
    thumbnail:film5
  },
  { 
    original:film6,
    thumbnail:film6
  },
  { 
    original:film7,
    thumbnail:film7
  },
  
]

export const quant = [
  {
    original: quant1,
    thumbnail: quant1,
  },
  {
    original: quant2,
    thumbnail: quant2,
  },
  {
    original: quant3,
    thumbnail: quant3,
  },
  {
    original: quant4,
    thumbnail: quant4,
  },
  {
    original: quant5,
    thumbnail: quant5,
  },
  {
    original: quant6,
    thumbnail: quant6,
  },

  {
    original: quant7,
    thumbnail: quant7,
  },
  {
    original: quant8,
    thumbnail: quant8,
  },
  {
    original: quant9,
    thumbnail: quant9,
  },
  {
    original: quant10,
    thumbnail: quant10,
  },
  {
    original: quant11,
    thumbnail: quant11,
  },
  {
    original: quant12,
    thumbnail: quant12,
  },
  {
    original: quant13,
    thumbnail: quant13,
  },
  {
    original: quant14,
    thumbnail: quant14,
  },
  {
    original: quant15,
    thumbnail: quant15,
  },
  {
    original: quant16,
    thumbnail: quant16,
  },

  {
    original: quant17,
    thumbnail: quant17,
  },
  {
    original: quant16,
    thumbnail: quant16,
  },
  {
    original: quant18,
    thumbnail: quant18,
  },
  {
    original: quant19,
    thumbnail: quant19,
  },
  {
    original: quant20,
    thumbnail: quant20,
  },
  {
    original: quant21,
    thumbnail: quant21,
  },
  {
    original: quant22,
    thumbnail: quant22,
  },
  {
    original: quant23,
    thumbnail: quant23,
  },
  {
    original: quant24,
    thumbnail: quant24,
  },
  {
    original: quant25,
    thumbnail: quant25,
  },
  {
    original: quant26,
    thumbnail: quant26,
  },
  {
    original: quant27,
    thumbnail: quant27,
  },
  {
    original: quant28,
    thumbnail: quant28,
  },
  {
    original: quant29,
    thumbnail: quant29,
  },
  {
    original: quant30,
    thumbnail: quant30,
  },
  {
    original: quant31,
    thumbnail: quant31,
  },
  {
    original: quant32,
    thumbnail: quant32,
  },
  {
    original: quant33,
    thumbnail: quant33,
  },
  {
    original: quant34,
    thumbnail: quant34,
  },
];

export const photomen = [
  {
    original: men1,
    thumbnail: men1,
  },
  {
    original: men2,
    thumbnail: men2,
  },
  {
    original: men3,
    thumbnail: men3,
  },
  {
    original: men4,
    thumbnail: men4,
  },
  {
    original: men5,
    thumbnail: men5,
  },
];

export const photo0 = [
  {
    original: imges1,
    thumbnail: imges1,
  },
  {
    original: imges2,
    thumbnail: imges2,
  },
  {
    original: imges3,
    thumbnail: imges3,
  },
  {
    original: imges4,
    thumbnail: imges4,
  },
  {
    original: imges5,
    thumbnail: imges5,
  },
  {
    original: imges6,
    thumbnail: imges6,
  },
  {
    original: imges7,
    thumbnail: imges7,
  },
  {
    original: imges8,
    thumbnail: imges8,
  },
  {
    original: imges9,
    thumbnail: imges9,
  },
  {
    original: imges10,
    thumbnail: imges10,
  },
  {
    original: imges11,
    thumbnail: imges11,
  },
  {
    original: imges12,
    thumbnail: imges12,
  },
  {
    original: imges13,
    thumbnail: imges13,
  },
  {
    original: imges14,
    thumbnail: imges14,
  },
  {
    original: imges15,
    thumbnail: imges15,
  },
  {
    original: imges16,
    thumbnail: imges16,
  },
  {
    original: imges17,
    thumbnail: imges17,
  },
  {
    original: imges18,
    thumbnail: imges18,
  },
  {
    original: imges19,
    thumbnail: imges19,
  },
  {
    original: imges20,
    thumbnail: imges20,
  },
  {
    original: imges21,
    thumbnail: imges21,
  },
];

export const img = [
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img5,
    thumbnail: img5,
  },
  {
    original: img6,
    thumbnail: img6,
  },
];
