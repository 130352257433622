import React, { useEffect } from "react";
import "../Webdeveloper/webdev.css";
import webdevimges from "../../assets/images/Web-development-2.png";
// import webdevelopment from "../src/imges/Web-development-3.png";
import { useTranslation } from "react-i18next";
import Nvabar from "../Navbar/Nvabar";
import Footer from "../page/Footer/Footer";
import JointTeam from "../Jointeam/JoinTeam";
import Aos from "aos";
function WebDev() {
  const {t} = useTranslation()
  useEffect(()=>{
    Aos.init({duration:500})
 })
  return (
    <>
      <Nvabar />
      <div className="container-webDev"></div>
      <div className="container-webDev-text">
        <div className="img-text-cont-webDev">
          <h2 data-aos="fade-right" className="about-dev"> {t("WebDevelopment.1")} </h2>
        </div>
        <div className="languages">
          <p data-aos="fade-right" className="row-dev">
            {t("WebDevelopment.2")}
          </p>
        </div>
      </div>
      <div className="dev-imges">
        <img src={webdevimges} className="webdev" alt="" />
      </div>
      <div className="container_text_ux_">
        <div className="text-webdev">
          <p data-aos="fade-right" className="row-dev">
            {t("WebDevelopment.3")}
              
          </p>
        </div>
      </div>

      <div className="web-developmet">
      <div className="line-black"></div>

        <JointTeam />
      </div>
      <Footer />
    </>
  );
}

export default WebDev;
