import { Route, Routes } from "react-router-dom";
import Home  from  "./components/Home/Home"
import Gallery from "./components/Gallery/Gallery";
// import Footer from "./page/Footer/Footer";
import Graphic from "./components/Graphic/Graphic";
import School from "./components/School/School";
import GraphicCurse  from "./components/GraphicCurse/GraphicCurse"
import WebDesign from "./components/Webdesign/Webdes";
import WebDev from "./components/Webdeveloper/Webdev";
import Smm from "./components/Smm/Smm";
import BookDesign from "./components/BookDesign/BookDesign";
import StudentWork from "./components/StWorks/StudentWork";
import WebCurse from "./components/Webcurse/WebCurse";
import AppDesgn from "./components/Appdesign/AppDesign";
import OurProject from "./components/OurProject/OurProjet";
import UiUxdesign from "./components/UiUX/UiUxdesign";
import ContactUs from "./components/ContactUs/ContactUs";
import WiewCareers from "./components/WiewCareers/WiewCareers";
function App() {
  return (
    <div className="App">
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Gallery />} />
        <Route path="/graphic" element={<Graphic />} />
        <Route path="/school" element={<School />} />
        <Route path="/graphic-course" element={<GraphicCurse />} />
        <Route path="/ui-ux-course" element={<UiUxdesign />} />
        <Route path="/smm" element={<Smm />} />
        <Route path="/web-design" element={<WebDesign />} />
        <Route path="/web-development" element={<WebDev />} />
        <Route path="/book" element={<BookDesign />} />
        <Route path="/students-work" element={<StudentWork/>} />
        <Route path="/web-course" element={<WebCurse />} />
        <Route path="/app-design" element={<AppDesgn />} />
        <Route path="/our-projects" element={<OurProject />} />
        <Route path="/ui-ux" element={<UiUxdesign />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/wiewcareers" element={<WiewCareers/>}/>
      </Routes>
    </div>
  );
}

export default App;
