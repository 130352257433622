
import { useTranslation } from "react-i18next"
import flagArm from "../../assets/images/Logo/armenia (1).png"
import flagEng from "../../assets/images/Logo/united-kingdom.png"
import "../LenguageSwitcher/LenguageSwitcher.css"

 export const   LenguageSwitcher = () => {
       const {i18n} = useTranslation()
      
      const switcher = (lng) => ( ) => {
        i18n.changeLanguage(lng)
      }
      
      return (
          <>
             <div className="fleg_container">
                <img className="flagA" onClick={switcher('am')} src={flagArm} alt=""/>
                <img  className="flagE" onClick={switcher('en')} src={flagEng} alt=""/>
             </div>
           
          </>
        
      )
}