import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import "../Appdesign/appdesign.css";
import JointTeam from "../Jointeam/JoinTeam";
import Footer from "../page/Footer/Footer";
import Nvabar from "../Navbar/Nvabar";
import Aos from "aos";
function AppDesgn() {
  const { t } = useTranslation();
  useEffect(()=>{
    Aos.init({duration:500})
 })
  return (
    <>
      <Nvabar />
      <div className="appdesImges">
        <div className="apptext_1">
          <div className="title__">
            <h1 data-aos="fade-right" className="titel_01">{t("AppDesign.1")}</h1>
          </div>
          <div className="text-app">
            <p data-aos="fade-right" className="row-ap">
            {t("AppDesign.2")}
            </p>
          </div>
        </div>
        <div className="app-text2-cont">
        <div className="app-titel-2">
          <div className="titel_app">
            <h2 data-aos="fade-right" className="app_t_l">
            {t("AppDesign.3")}
            </h2>
          </div>
        </div>
        <div className="our-service-app">
          <p data-aos="fade-right" className="row-app-2">
          {t("AppDesign.4")}
          </p>
        </div>
      </div>
        <JointTeam  />
      </div>
     

      
      <Footer />
    </>
  );
}

export default AppDesgn;
