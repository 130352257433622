import React, { useEffect } from "react";

import "../Smm/smm.css";
import Nvabar from "../Navbar/Nvabar";
import Footer from "../page/Footer/Footer";
import JointTeam from "../Jointeam/JoinTeam";
import { useTranslation } from "react-i18next";
import Aos from "aos";
function Smm() {
  const {t} = useTranslation()
  useEffect(()=>{
    Aos.init({duration:500})
 },[])
  return (
    <>
      <Nvabar />
      <div className="imge-container"></div>

      <div className="smm-text">
        <div className="icon-text-smm">
          <h2 data-aos="fade-right"  className="smm-title">{t("SMM.1")}</h2>
        </div>
        <div className="text-p">
          <p data-aos="fade-right"  className="row-sm">
               {t("SMM.2")}
          </p>
        </div>
      </div>
      <div className="smmCover2">
        <div className="smm-images-c ">
        <JointTeam />

        </div>
      </div>
      <Footer />
    </>
  );
}

export default Smm;
