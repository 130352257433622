import axios from 'axios';
import '../WiewCareers/wiewcareers.css'
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Nvabar from '../Navbar/Nvabar';
import Footer from '../page/Footer/Footer';

function WiewCareers() {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phoneNumber: '',
    message: '',
    loadCv: ''
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://api.dev.itfabers.com/api/send-contact-email', formData);
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };
  const { t } = useTranslation()
  return (
    <>
      <Nvabar />
      <form ref={form} onSubmit={handleSubmit}>
        <div className="cotact_us_form_join">
          <div className="titel_reg_contact_join">
            <h1 className="getintouch">{t("HOME.6")}</h1>
          </div>
          <div className="cont_user-name_lastname_reg">
            <div className="reg_box">
              <label for="validationDefault01" className="form-label">
                {t("Register_1.4")}
              </label>
              <input
                type="text"
                name="to_name"
                className="form-control"
                id="validationDefault01"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
                placeholder={t("Register_1.4")}
              />
            </div>
            <div className="reg_box">
              <label for="validationDefault02" className="form-label">
                {t("Register_1.6")}
              </label>
              <input
                type="text"
                className="form-control"
                id="validationDefault02"
                aria-describedby="inputGroupPrepend2"
                value={formData.surname}
                onChange={(e) => setFormData({ ...formData, surname: e.target.value })}
                required
                placeholder={t("Register_1.0")}
              />
            </div>
          </div>
          <div className="cont_user-name_lastname_reg">
            <div className="reg_box">
              <label for="validationDefaultUsername" className="form-label">
                {t("Register_1.7")}
              </label>
              <div className="input-group">
                <span className="input-group-text" id="inputGroupPrepend2">
                  @
                </span>
                <input
                  type="email"
                  name="to_email"
                  className="form-control"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                  placeholder="name@gmail.com"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  required
                />
              </div>
            </div>
            <div className="reg_box">
              <label for="validationDefaultUsername" className="form-label">
                {t("Register_1.9")}
              </label>
              <div className="input-group">
                <input
                  type="phone"
                  name="to_phone"
                  className="form-control"
                  id="validationDefaultUsername"
                  aria-describedby="inputGroupPrepend2"
                  placeholder="+374 00 123456"
                  value={formData.phoneNumber}
                  onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          <div>
            <input className="form-floating text_area" type="file" id="formFile" onChange={(e) => setFormData({ ...formData, loadCv: e.target.value })} />
          </div>
          <div className="form-floating">
            <textarea
              className="form-control text_area"
              placeholder={t("Register_1.17")}
              id="message"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}

            ></textarea>
          </div>
          <div className="col-12 btnReg">
            <button className="btn btn-primary-join" type="submit">
              {t("Register_1.18")}
            </button>
          </div>
        </div>
      </form>
      <Footer />
    </>
  )
}


export default WiewCareers