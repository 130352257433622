import { NavLink } from "react-router-dom";

import logoBalck from "../../assets/images/LogoBlack.png";

import "uikit/dist/css/uikit-core.min.css";
import "uikit/dist/css/uikit.min.css";
import "../Navbar/navbar.css";
import menu from "../../assets/images/Logo/menu.png";
import logoHome from "../../assets/images/Logo.png";
import { LenguageSwitcher } from "../LenguageSwitcher/LenguageSwitcher";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { AiOutlineInstagram } from "react-icons/ai"
import { FaFacebookF } from "react-icons/fa"
import { FaTwitter } from 'react-icons/fa'
import { BsBehance } from "react-icons/bs"
import { FaPinterestP } from "react-icons/fa"
import { BiLogoLinkedin } from "react-icons/bi"
function Nvabar() {
  const { t } = useTranslation()
  return (
    <>
      <div className="response_nav">
        <div className="logo_home">
          <NavLink className="text_m" to="/">
            <img src={logoBalck} className="logoBalck" alt="" />
          </NavLink>
        </div>
        <div className="buton">
          <img
            src={menu}
            className="menu"
            alt=""
            uk-toggle="target: #offcanvas-overlay"
          />
          <div id="offcanvas-overlay" uk-offcanvas="overlay: true">
            <div className="uk-offcanvas-bar">
              <button className="uk-offcanvas-close" type="button">
                <MdClose />
              </button>

              <NavLink className="text_m" to="/">
                <img src={logoHome} className="logoBalck" alt="" />
              </NavLink>
              <div className="uk-width-1-2@s uk-width-2-5@m">
                <ul className="uk-nav-default" uk-nav="multiple: true">
                  <li className="uk-active"><a href="/#"> {t('HOME.1')}</a></li>
                  <li className="uk-parent">
                    <a href="/#">  {t('Sevices.5')} <span uk-nav-parent-icon="true"></span></a>
                    <ul className="uk-nav-sub">
                      <li>
                        <NavLink className="text_m" to="/graphic">
                          {" "}
                          {t("Sevices.1")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="text_m" to="/web-design">
                          {t("Sevices.3")}
                        </NavLink>
                        <ul>
                          <li>
                            <NavLink className="text_m" to="/web-development">
                              {t("Sevices.4")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="text_m" to="/book">
                              {t("Sevices.6")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="text_m" to="/app-design">
                              {t("Sevices.7")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="text_m" to="/smm">
                              {t("Sevices.2")}
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <NavLink className="text_m" to="/our-projects">
                    {t("HOME.3")}
                  </NavLink>
                  <li className="uk-parent">
                    <a href="/#">{t('HOME.4')} <span uk-nav-parent-icon="true"></span></a>
                    <ul className="uk-nav-sub">
                      <li> <NavLink className="text_m" to="/graphic-course"> {t("Sevices.9")} </NavLink></li>
                      <li> <NavLink className="text_m" to="/ui-ux">{t("Sevices.10")}</NavLink></li>
                      <li> <NavLink className="text_m" to="/web-course">{t("Sevices.8")}</NavLink></li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="link_container">
                <span>
                  <NavLink className="text_m" to="/contact-us">
                    {t("HOME.5")}
                  </NavLink>
                </span>
              </div>
              <div className="footer_container">
                <div className="footer_box">
                  <div className="contact_text">
                    <p>info@lightdesignestudio.am</p>
                    <p>+374 77 18 89 19,  +374 77 89 10 98 </p>
                    <p>Yerevan, Armenia, Gevorg Kochar 21</p>
                    <LenguageSwitcher />
                  </div>
                  <div className="contact_icon">
                    <a href="https://www.facebook.com/lightdesignestudio" target="blank"> <p className="logo"><FaFacebookF /></p></a>
                    <a href="https://instagram.com/light.design.studio?igshid=NTc4MTIwNjQ2YQ==" target="blank"> <p className="logo"> <AiOutlineInstagram /> </p> </a>
                    <a href="/#"><p className="logo"><FaTwitter /></p> </a>
                    <a href="https://www.behance.net/lightdesign" target="blank"><p className="logo"><BsBehance /></p></a>
                    <a href="/#"><p className="logo"><FaPinterestP /></p></a>
                    <a href="https://www.linkedin.com/company/90788683/admin/" target="blank"><p className="logo"><BiLogoLinkedin /></p></a>
                  </div>
                  <div className="date-of-creation">
                    <span className="data_p">2019 Light Design Studio</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      <div className="navbar_1">
        <div className="nav_cont">
          <div className="logo_home">
            <NavLink className="text_m" to="/">
              <img src={logoBalck} alt="" />
            </NavLink>
          </div>
          <div className="menu-nav">
            <NavLink className="link_n" to="/about">
              {t('HOME.1')}
            </NavLink>

            <div className="link_l">
              <span className="link_n" style={{ cursor: "pointer" }}>{t("HOME.2")}</span>
              <div
                className="uk-card uk-card-body uk-card-default card-hover"
                uk-drop="animate-out: true"
              >
                <div className="link_container_service">
                  <NavLink className="navL" to="/graphic">
                    <p className="link_p_sevice">
                      {t("Sevices.1")}
                    </p>
                  </NavLink>
                  <NavLink className="navL" to="/web-design">
                    <p className="link_p_sevice">
                      {t("Sevices.3")}
                    </p>
                  </NavLink>
                  <NavLink className="navL" to="/book">
                    <p className="link_p_sevice">
                      {t("Sevices.6")}
                    </p>
                  </NavLink>
                  <NavLink className="navL" to="/app-design">
                    <p className="link_p_sevice">
                      {t("Sevices.7")}
                    </p>
                  </NavLink>
                  <NavLink className="navL" to="/smm">
                    <p className="link_p_sevice">
                      {t("Sevices.2")}
                    </p>
                  </NavLink>
                  <NavLink className="navL" to="/web-development">
                    <p className="link_p_sevice">
                      {t("Sevices.4")}
                    </p>
                  </NavLink>
                </div>
              </div>
            </div>

            <NavLink className="link_n" to="/our-projects">
              {t("HOME.3")}
            </NavLink>
            <div className="uk-inline link_n"> 
              <NavLink to="/school" >
                <span className="link_n">{t("HOME.4")}</span>
              </NavLink>
              <div
                className="uk-card uk-card-body uk-card-default card-hover"
                uk-drop="animate-out: true"
              >
                <span>
                  <NavLink className="link_n" to="/graphic-course">
                    {t("Sevices.9")}
                  </NavLink>
                </span>
                <span>
                  <NavLink className="link_n" to="/ui-ux">
                    {t("Sevices.10")}
                  </NavLink>
                </span>
                <span>
                  <NavLink className="link_n" to="/web-course">
                    {t("Sevices.8")}
                  </NavLink>
                </span>
              </div>
            </div>
              

          

            <NavLink className="link_n" to="/contact-us">
              {t('HOME.5')}
            </NavLink>
          </div>
          <LenguageSwitcher />
        </div>
      </div>
    </>
  );
}

export default Nvabar;
