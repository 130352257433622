import "../Gallery/gallery.css";
import Nvabar from "../Navbar/Nvabar";
import imagesS1 from "../../assets/images/Light-Design-Studio-Services-&-Price-lists.jpg";
import sericesImg2 from "../../assets/images/Rectangle 19.png";
import sericesImg3 from "../../assets/images/Rectangle 25.png";
import sericesImg5 from "../../assets/images/Rectangle 20 (1).png";
import sericesImg6 from "../../assets/images/homepage--web-dev.png";
import newProjectsImg1 from "../../assets/images/newProjectsImg (4).png";
import newProjectsImg2 from "../../assets/images/newProjectsImg (5).png";
import newProjectsImg3 from "../../assets/images/Frame 8.png";
import newProjectsImg4 from "../../assets/images/newProjectsImg (1).png";
import newProjectsImg5 from "../../assets/images/newProjectsImg (3).png";
import newProjectsImg6 from "../../assets/images/newProjectsImg (2).png";
import newProjectsImg7 from "../../assets/images/newProjectsImg (8).png";
import newProjectsImg8 from "../../assets/images/newProjectsImg (7).png";
import newProjectsImg9 from "../../assets/images/newProjectsImg (6).png";
import Footer from "../page/Footer/Footer.jsx";
import { Link } from "react-router-dom";
import {  useTranslation } from "react-i18next"
import {
  avanta,
   black,
   book,
   club,
   myBooks,
   nur,
   onBoard,
  shoper,
} from "../../WorkSt/ourProjectImg";
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect, useState } from "react";
import "@fancyapps/ui/dist/carousel/carousel.css";
import Modal from 'react-modal'
import ImageGallery from 'react-image-gallery';
import { MdClose } from "react-icons/md";
import { useMediaPredicate } from "react-media-hook";

function Gallery() {
  const {t} = useTranslation()
  useEffect(()=>{
     Aos.init({duration:500})
  },[])
  const ourProjectImg = [
    {img:newProjectsImg1,id:onBoard},
    {img:newProjectsImg2,id:club},
    {img:newProjectsImg3,id:shoper},
    {img:newProjectsImg4},
    {img:newProjectsImg5,id:avanta},
    {img:newProjectsImg6,id:book},
    {img:newProjectsImg7,id:myBooks},
    {img:newProjectsImg8,id:nur},
    {img:newProjectsImg9,id:black },
  ]
   
  const biggerThan = useMediaPredicate("(max-width: 768px)");
   const [activPopup, setActivPopup] = useState(onBoard)
   const [modalIsOpen, setIsOpen] = useState(false);
   const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  
function openModal() {
  setIsOpen(true);
}


function closeModal() {
  setIsOpen(false);
}
  return (
    <>
      <Nvabar />
      <div className="gallery-contaner ">
        <div className="about_us_container">
          <div className="cover_c">
            <div className="cover_about_n1"></div>
          </div>
          <div className="about_text_c">
            <div className="about_text">
              <div>
                <h2 data-aos="fade-right"  className="titel_a">{t("Sevices.11")}</h2>
              </div>
                
              <div data-aos="fade-right" className="text_g">
                <p className="text_s_ab">
                     {t("AboutUS.1")}
                </p>
              </div>
            </div>
          </div>
         
        

          <div className="about_cover_c2">
            <div className="cover1_service">
              <div className="imgesServicCover"></div>
            </div>
            <div className="servicImages_container">
              <div className="service_titel">
                <h2 className="main-titel_service">{t('HOME.2')}</h2>
              </div>
              <div className="images_s_c">
                <div className="images_c">
                  <div className="text_s">
                    <h3 className="sevice_text_titel">{t("Sevices.1")}</h3>
                  </div>

                  
                  <div className="images_s_1">
                      <div className="uk-text-center">
        <div className="uk-inline-clip uk-transition-toggle" tabindex="0">
                    <Link to="/graphic"> <img src={imagesS1} className="imagesS1" alt="" /></Link>
            <div className="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-defaultcolor_hover">
                <p className="uk-h4 uk-margin-remove hover_p ">{t("Sevices.1")}</p>
            </div>
        </div>
    </div>

                  </div>
                </div>
                <div className="images_c">
                  <div className="text_s">
                    <h3 className="sevice_text_titel">{t("Sevices.3")}</h3>
                  </div>
                  <div className="images_s_1">
                      <div className="uk-text-center">
        <div className="uk-inline-clip uk-transition-toggle" tabindex="0">
                    <Link to="/web-design"> <img src={sericesImg2} className="imagesS1" alt="" /></Link>
            <div className="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default color_hover">
                <p className="uk-h4 uk-margin-remove hover_p ">{t("Sevices.3")}</p>
            </div>
        </div>
       
    </div>

                  </div>
                </div>
                <div className="images_c">
                  <div className="text_s">
                    <h3 className="sevice_text_titel">{t("Sevices.6")}</h3>
                  </div>
                  <div className="images_s_1">
                      <div className="uk-text-center">
        <div className="uk-inline-clip uk-transition-toggle" tabindex="0">
                    <Link to="/book"> <img src={sericesImg3} className="imagesS1" alt="" /></Link>
            <div className="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default color_hover">
                <p className="uk-h4 uk-margin-remove hover_p ">{t("Sevices.6")}</p>
            </div>
        </div>
       
    </div>

                  </div>
                </div>
                <div className="images_c">
                  <div className="text_s">
                    <h3 className="sevice_text_titel">{t("Sevices.7")}</h3>
                  </div>
                  <div className="images_s_1">
                      <div className="uk-text-center">
        <div className="uk-inline-clip uk-transition-toggle" tabindex="0">
                    <Link to="/app-design"> <img src={sericesImg5} className="imagesS1" alt="" /></Link>
            <div className="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default color_hover">
                <p className="uk-h4 uk-margin-remove hover_p ">{t("Sevices.7")}</p>
            </div>
        </div>
       
    </div>

                  </div>
                </div>
                <div className="images_c">
                  <div className="text_s">
                    <h3 className="sevice_text_titel">{t("Sevices.2")}</h3>
                  </div>
                  <div className="images_s_1">
                      <div className="uk-text-center">
        <div className="uk-inline-clip uk-transition-toggle" tabindex="0">
                    <Link to="/smm"> <img src={sericesImg6} className="imagesS1" alt="" /></Link>
            <div className="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default color_hover">
                <p className="uk-h4 uk-margin-remove  hover_p ">{t("Sevices.2")}</p>
            </div>
        </div>
       
    </div>

                  </div>
                </div>
                <div className="images_c">
                  <div className="text_s">
                    <h3 className="sevice_text_titel">{t("Sevices.4")}</h3>
                  </div>
                  <div className="images_s_1">
                      <div className="uk-text-center">
                           <div className="uk-inline-clip uk-transition-toggle" tabindex="0">
                    <Link to="/web-development"> <img src={imagesS1} className="imagesS1" alt="" /></Link>
                       <div className="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-default color_hover">
                    <p className="uk-h4 uk-margin-remove hover_p ">{t("Sevices.4")}</p>
            </div>
        </div>
       
    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="service_cover_n3">
            <div className="serviceCover3"></div>
          </div>
        </div>
        <div className="new_progects_container">
          <div className="service_titel">
            <h2 className="main-titel_service">{t("Sevices.12")}</h2>
          </div>
          <div className="new-progect-iages-cont">
          
              {
                ourProjectImg.map((item,index)=>{
                    return (
                           <>
                            <img style={{cursor:"pointer"}} key={Math.random() * index} src={item.img} className="newProjectsImg1" alt="images_our" onClick={()=>{
                                setActivPopup(item.id)
                                console.log(activPopup);
                                openModal()
                            }}/>
                           </>
                    )
                })
              }
               <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                      ariaHideApp={false}
                     >        
                   <div className="slider_container_or_btn">
                    <div className="clos_btn_container">
                    <MdClose onClick={closeModal} className="close_path"/>
                    </div>
                   
                   <ImageGallery disableThumbnailScroll={false}  thumbnailPosition = {biggerThan ? "bottom" : "left"} items = {activPopup}/>
                   </div>
                  
      </Modal>
           </div>
        </div>
      </div>
      <div>
      
    </div>
      <Footer />
    </>
  );
}

export default Gallery;
