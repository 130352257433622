import "../Footer/footer.css";
import fb from "../../../assets/images/Logo/Path 22.png";
import insta from "../../../assets/images/Logo/Path 23.png";
import tw from "../../../assets/images/Logo/Path 24.png";
import bh from "../../../assets/images/Logo/Path 29.png";
import pin from "../../../assets/images/Logo/Path 30.png";
import linkdin from "../../../assets/images/Logo/Group 47.png";
import c from "../../../assets/images/Logo/Group 49.png";

function Footer() {
  return (
    <>
      <div className="footer_container">
        <div className="footer_box">
          <div className="contact_text">
           
            
            <p>info@lightdesignestudio.am</p>
            <p>+374 77 18 89 19,  +374 77 89 10 98 </p> 
            <p>Yerevan, Armenia, Gevorg Kochar 21</p>
          </div>
          <div className="contact_icon">
            <a
              href="https://www.facebook.com/lightdesignestudio"
              target="blank"
            >
              <div className="logo">
                <img className="logo-img" src={fb} alt="" />
              </div>
            </a>
            <a
              href="https://instagram.com/light.design.studio?igshid=NTc4MTIwNjQ2YQ=="
              target="blank"
            >
              <div className="logo">
                <img src={insta} alt="" />
              </div>
            </a>
            <a href="/#">
              <div className="logo">
                <img src={tw} alt="" />
              </div>
            </a>

            <a href="https://www.behance.net/lightdesign" target="blank">
              <div className="logo">
                <img className="logo-img" src={bh} alt="" />
              </div>
            </a>
            <a href="/#">
              <div className="logo">
                <img className="logo-img" src={pin} alt="" />
              </div>
            </a>
            <a
              href="https://www.linkedin.com/company/90788683/admin/"
              target="blank"
            >
              <div className="logo">
                <img  className="logo-img" src={linkdin} alt="" />
              </div>
            </a>
          </div>
          <div className="date-of-creation">
            <img className="logo-img" src={c} alt="" />
            <span className="data_p">2019 Light Design Studio</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
