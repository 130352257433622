                              
import work1 from "../src/imgesStudentsWork/Graphic-design-course--luminis.png";
import work2 from "../src/imgesStudentsWork/Graphic-design-course--page-buir.png";
import work3 from "../src/imgesStudentsWork/Graphic-design-course--page-Glass-of.png";
import work4 from "../src/imgesStudentsWork/Graphic-design-course--poem.png";
import work5 from "../src/imgesStudentsWork/Graphic-design-course--page-ofus.png";
import work6 from "../src/imgesStudentsWork/Graphic-design-course--green-zone.png";
import work7 from "../src/imgesStudentsWork/Graphic-design-course--page-hooday.png";
import work8 from "../src/imgesStudentsWork/Graphic-design-course--page-loli-pop.png";
import work9 from "../src/imgesStudentsWork/Graphic-design-course--page-mandarin.png";
import work10 from "../src/imgesStudentsWork/Graphic-design-course--page-meliksetsyan.png";
import work11 from "../src/imgesStudentsWork/Graphic-design-course--page-gift.png";
import work12 from "../src/imgesStudentsWork/Graphic-design-course--donut.png";
import work13 from "../src/imgesStudentsWork/Graphic-design-course--page-10.png";
import work14 from "../src/imgesStudentsWork/Graphic-design-course--page-11.png";
import work15 from "../src/imgesStudentsWork/Graphic-design-course--page-12.png";
import work16 from "../src/imgesStudentsWork/Graphic-design-course--page13.png";
import work17 from "../src/imgesStudentsWork/Graphic-design-course--page-14.png";
import work18 from "../src/imgesStudentsWork/Graphic-design-course--page-15.png";
import work19 from "../src/imgesStudentsWork/Graphic-design-course--page16.png";
import work20 from "../src/imgesStudentsWork/Graphic-design-course--page-17.png";
import work21 from "../src/imgesStudentsWork/Graphic-design-course--page-18.png";
import project1 from "../src/projectImges/project1.png";
import project2 from "../src/projectImges/project2.png";
import project3 from "../src/projectImges/project3.png";
import project4 from "../src/projectImges/project4.png";
import project5 from "../src/projectImges/project5.png";
import project6 from "../src/projectImges/project6.png";
import project7 from "../src/projectImges/project7.png";
import project8 from "../src/projectImges/project8.png";
import project9 from "../src/projectImges/project9.png";
import project10 from "../src/projectImges/project10.png";
import project11 from "../src/projectImges/project11.png";
import project12 from "../src/projectImges/project12.png";
import project13 from "../src/projectImges/project13.png";
import project14 from "../src/projectImges/project14.png";
import project15 from "../src/projectImges/project15.png";
import project16 from "../src/projectImges/project16.png";
import project17 from "../src/projectImges/project17.png";
import project18 from "../src/projectImges/project18.png";
import project19 from "../src/projectImges/project19.png";
import project20 from "../src/projectImges/project20.png";
import project21 from "../src/projectImges/project21.png";
import project22 from "../src/projectImges/project22.png";
import coverImg from "../src/projectImges/Cover.jpg";
import coverImg2 from "../src/projectImges/Cover (1).jpg";
import coverImg3 from "../src/projectImges/cover (2).jpg";
import coverImg4 from "../src/projectImges/Cover (3).jpg";
import coverImg5 from "../src/projectImges/Cover (5).jpg";
import coverImg6 from "../src/projectImges/Cover (6).jpg";
import coverImg7 from "../src/projectImges/Calipso-cover.jpg";
import coverImg8 from "../src/projectImges/Cover (7).jpg";
import { 
  artsakh,
  buyr, 
  bw, 
  cakeOclockBrand1, 
  calipso, 
  donut, 
  film, 
  giftSop, 
  glasseOf, 
  glowing, 
  greenZone, 
  hoode, 
  img, 
  lolli, 
  luminis, 
  lusnazard, 
  meliqsetyans, 
  mnadarin, 
  ofus, 
  oxygen, 
  photo, 
  photo0, 
  photomen, 
  photoshop, 
  poeme,
  poster, 
  
  posters, quant, zooty 
} from "../src/imgesWork";
import { alias, avanta, black, book, bookChristmas, club, everest, hassina, kahuqmall, ledy, medicos, myBooks, nur, onBoard, photoG, shoper, tallest, vmjew, xPlant } from "./WorkSt/ourProjectImg";

export const studentsWork = [
    
  { id: luminis, 
    img: work1,
    StudentWorkName: "Luminis photo studio",
    StudentName: "Marietta Iskoyan"
  },
  { id: buyr, 
    img: work2,
    StudentWorkName: "Buir Candle Branding",
    StudentName: "Arevik Harutyunyan"
  },
  { id: glasseOf, 
    img: work3,
    StudentWorkName: "Glass of Lounge Bar & Restaurant",
    StudentName: "Gor Muradia",
  },
  { id: poeme, 
    img: work4,
    StudentWorkName: "Poeme bookcafe",
    StudentName: "Jen Frangulyan",
   },
  { id: ofus,
     img: work5,
     StudentWorkName: "OFUS Fashion Brand",
     StudentName: "Ofus Vardanyan",
    },
  { id: greenZone, 
    img: work6,
    StudentWorkName: "Green zone kids club",
    StudentName: "Qnar Margaryan",
  },
  { id: hoode, 
    img: work7,
    StudentWorkName: "Hooday Clothing Brand",
    StudentName: "Annie Petrosyan",
  },
  { id: lolli,
     img: work8,
     StudentWorkName: "LolliPop kids clothes branding",
     StudentName: "Sima Grigoryan", 
    },
  { id: mnadarin,
     img: work9,
     StudentWorkName: "Mandarin cafe and restaurant",
     StudentName: "Mary Aghasaryan", 
   },
  { id: meliqsetyans,
     img: work10,
     StudentWorkName: "Meliksetyan's Fashion Brand",
     StudentName: "Ann Meliksetyan", 
    },
  { id: giftSop, 
    img: work11,
    StudentWorkName: "The Gift Shop Branding",
    StudentName: "Nune Rostomyan",
  },
  { id: donut, 
    img: work12,
    StudentWorkName: "Lusnazard Branding",
    StudentName: "Lusine Grigoryan", 
  },
  { id: lusnazard, 
    img: work13,
    StudentWorkName: "Donut Branding",
    StudentName: "Tatev Ayvazyan", 
  },
  
  { id: oxygen, 
    img: work14,
    StudentWorkName: "ZOOTY kids beauty center branding",
    StudentName: "Karina Vagharshakyan", 
  },
  { id: zooty, 
    img: work15,
    StudentWorkName: "Oxygen Brand",
    StudentName: "Gayane Alaverdyan",
  },
  
  { id: cakeOclockBrand1, 
    img: work16,
    StudentWorkName: "Cake O'clock Brand",
    StudentName: "Arman Hambardzumyan", 
  },
  { 
    id: glowing, 
    img: work17,
    StudentWorkName: "Glowing Flowers",
    StudentName: "Ruz Zaqaryan",
  },
  { id: bw, 
    img: work18,
    StudentWorkName: "b&w posters",
    StudentName: "Marietta Iskoyan", 
  },
  { id: poster, 
    img: work19,
    StudentWorkName: "Poster Illustration",
    StudentName: "Annie Petrosyan",
  },
  { id: photoshop, 
    img: work20,
    StudentWorkName: "Photoshop projects",
    StudentName: "Qnar Margaryan",
  },
  { id: posters, 
    img: work21,
    StudentWorkName: "Posters collection",
    StudentName: "Nadia Abrahamyan", 
  },
  { id:photo , 
    img: coverImg,
    StudentWorkName: "",
    StudentName: "", 
  },
  { id: artsakh, 
    img: coverImg2,
    StudentWorkName: "",
    StudentName: "", 
  },
  { id: film, 
    img: coverImg3,
    StudentWorkName: "",
    StudentName: "", 
  },
  { id: quant, 
    img: coverImg4,
    StudentWorkName: "",
    StudentName: "", 
  },
  { id:  photomen, 
    img: coverImg5,
    StudentWorkName: "",
    StudentName: "", 
  },
  { id:   photo0, 
    img: coverImg6,
    StudentWorkName: "",
    StudentName: "", 
  },
  { id:calipso , 
    img: coverImg7,
    StudentWorkName: "",
    StudentName: "", 
  },
  { id: img, 
    img: coverImg8,
    StudentWorkName: "",
    StudentName: "",
  },
];

export const project_our1 = [
  {id: shoper, img: project1,text: "Shopper online shop"},
  {id:[], img: project2, text: "Shopper online shop" },
  {id: club, img: project3, text: "Shopper online shop" },
  {id: medicos, img: project4, text: "Shopper online shop" },
  {id: onBoard, img: project5, text: "Shopper online shop" },
  {id: hassina, img: project6},
  {id: tallest, img: project7},
  {id: [] , img: project8},
  {id: avanta, img: project9},
  {id: alias, img: project10},
  {id: book, img: project11},
  {id: nur, img: project12},
  {id: bookChristmas, img: project13},
  {id: xPlant, img: project14},
  {id: kahuqmall, img: project15},
  {id: photoG, img: project16},
  {id: myBooks, img: project17},
  {id: vmjew, img: project18},
  {id: ledy, img: project19},
  {id: glowing, img: project20},
  {id: black, img: project22},
  {id: everest, img: project21},
 
 
];
