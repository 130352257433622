import "../ContactUs/contaus.css";
import Nvabar from "../Navbar/Nvabar";
import icon from "../../assets/images/Logo/Group 21.png";
import icon2 from "../../assets/images/Logo/Group 23.png";
import icon3 from "../../assets/images/Logo/Group 25.png";
import icon4 from "../../assets/images/Logo/Group 32.png";
import icon5 from "../../assets/images/Logo/Group 38.png";

import Footer from "../page/Footer/Footer";
import {useRef, useState} from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

function ContactUs() {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://api.dev.itfabers.com/api/send-contact-email', formData);
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

 const {t} = useTranslation()
  return (
    <>
      <Nvabar />
      <div className="contac-us-container">
        <div className="box_container_c">
          <div className="contact-box">
            <div className="contact-item">
              <div className="titel_contact_us">
                <h2 className="titel_contact">{t("HOME.5")}</h2>
              </div>
              <div className="container_contact_icon">
                <img src={icon} alt="" />
              </div>
              <div className="icon_sayt">
                <a
                  href="https://www.facebook.com/lightdesignestudio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon2} className="icon_images" alt="" />
                </a>
                <a
                  href="https://instagram.com/light.design.studio?igshid=NTc4MTIwNjQ2YQ=="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon3} className="icon_images" alt="" />
                </a>
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  <img src={icon4} className="icon_images" alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/90788683/admin/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon5} className="icon_images" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <form action="" ref={form} onSubmit={handleSubmit}>
          <div className="cotact_us_form">
            <div className="titel_reg_contact">
              <h1 className="getintouch">{t("HOME.6")}</h1>
            </div>
            <div className="cont_user-name_lastname_reg">
              <div className="reg_box">
                <label for="validationDefault01" className="form-label">
                   {t("Register_1.4")}
                </label>
                <input
                  type="text"
                  name="to_name"
                  className="form-control"
                  id="validationDefault01"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                  placeholder={t("Register_1.4")}
                />
              </div>
              <div className="reg_box">
                <label for="validationDefault02" className="form-label">
                {t("Register_1.6")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationDefault02"
                  aria-describedby="inputGroupPrepend2"
                  value={formData.surname}
                  onChange={(e) => setFormData({ ...formData, surname: e.target.value })}
                  required
                  placeholder={t("Register_1.0")}
                />
              </div>
            </div>
            <div className="cont_user-name_lastname_reg">
              <div className="reg_box">
                <label for="validationDefaultUsername" className="form-label">
                {t("Register_1.7")}
                </label>
                <div className="input-group">
                  <span className="input-group-text" id="inputGroupPrepend2">
                    @
                  </span>
                  <input
                    type="email"
                    name="to_email"
                    className="form-control"
                    id="validationDefaultUsername"
                    aria-describedby="inputGroupPrepend2"
                    placeholder="name@gmail.com"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    required
                  />
                </div>
              </div>
              <div className="reg_box">
                <label for="validationDefaultUsername" className="form-label">
                {t("Register_1.9")}
                </label>
                <div className="input-group">
                  <input
                    type="phone"
                    name="to_phone"
                    className="form-control"
                    id="validationDefaultUsername"
                    aria-describedby="inputGroupPrepend2"
                    placeholder="+374 00 123456"
                    value={formData.phoneNumber}
                    onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-floating">
             
              <textarea
                className="form-control text_area"
                placeholder={t("Register_1.17")}
                id="message"
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              ></textarea>
            </div>
            <div className="col-12 btnReg">
              <button className="btn btn-primary" type="submit">
              {t("Register_1.18")}
              </button>
            </div>
          </div>
        </form>
        <Footer />
      </div>
    </>
  );
}

export default ContactUs;
