import "uikit/dist/css/uikit-core.min.css";
import images1 from "../../assets/images//Graphic-design-course--program-eng.png";
import "../../components/GraphicCurse/graphicCurse.css";
import Nvabar from "../Navbar/Nvabar";
import trainerImages from "../../assets/images/trainer.png";
import workImages from "../../assets/images/Rectangle 67.png";
import workImages2 from "../../assets/images/Rectangle 63.png";
import workImages3 from "../../assets/images/Rectangle 33.png";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "../page/Footer/Footer";
import { NavLink } from "react-router-dom/dist";
import { useEffect, useRef, useState } from "react";
import Aos from "aos";
import { buyr, hoode, luminis } from "../../imgesWork";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"
import Modal from 'react-modal'
import { useMediaPredicate } from "react-media-hook";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { AiFillLike } from 'react-icons/ai';
function GraphicCurse() {
  const form = useRef();
  const {t} = useTranslation()
  
  useEffect(()=>{
    Aos.init({duration:500})
 },[])
  const work_cover_arr = [
    {id:luminis, img:workImages},
    {id:buyr, img:workImages2},
    {id:hoode, img:workImages3}
  ]
  const CoursesOption = [
    { value: 'Graphic Design ', label: 'Graphic Design ' },
    { value: 'UI/UX Design', label: 'UI/UX Design' },
    { value: 'Web Development', label: 'Web Development' },
  ];
  
  const CoursesType = [
    { value: 'Offline', label: 'Offline' },
    { value: 'Online', label: 'Online' },
  ];
  
  const [alertshow, setalertshow] = useState(false);
  const [disabledBtn, setdisabledBtn] = useState(false);
  const [activeSlider, setactiveSlider] = useState(luminis);
  const biggerThan = useMediaPredicate("(max-width: 768px)");
  const [x, setX] = useState(null);
  const [y, setY] = useState(null);
 
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const [modalIsOpen, setIsOpen] = useState(false);

function openModal() {
  setIsOpen(true);
}
const handleMouseMove =(e) => {
  const btn = e.target;
  const rect = btn.getBoundingClientRect()
     let x = e.pageX - rect.left;
     let y = e.clientY - rect.top;
     setX(x + 'px');
      setY(y + 'px'); 
      
}
 
function closeModal() {
  setIsOpen(false);
}
// const [isHovered, setIsHovered] = useState(false);

const formCurr = useRef();
const { register, handleSubmit, reset, control, formState: { errors } } = useForm({
  shouldFocusError: false, 
});
const onSubmit = () => {
  const Showelement = document.getElementById('sucsess_mode');
  if (Showelement) {
    setTimeout(() => {
      Showelement.scrollIntoView({ behavior: 'smooth' , block: "start", inline: "nearest" });
    });
  }
  sendEmail();
  setdisabledBtn(true);
  setalertshow(true);
};

const HandleRefresh=()=>{
  setalertshow(false);
  setdisabledBtn(false);
  const Hideelement = document.getElementById('hide_mode');
  if (Hideelement) {
    Hideelement.scrollIntoView({ behavior: 'smooth' });
  }
}

const sendEmail = () => {
  emailjs.sendForm('service_fqwggkv', 'template_evtejcs', formCurr.current, 'yk8KZOUcnNu0EV98F')
    .then((result) => {
      setTimeout(() => {
        reset();
        setalertshow(false);
        setdisabledBtn(false);
      }, 2000);
    }, (error) => {
      console.log(error.text);
    });
};
 
  return (
    <>
      <Nvabar />
      <div className="graphic_curs_container">
        <div className="cover_grCurse_1_container">
          <div className="cover_grCurse_1"></div>
        </div>
        <div className="graphic_curse_text_container">
        
           <div className="tiitel_container">
            <h2 data-aos="fade-right" className="main-titel_service">{t("graphic_design_course.1")} </h2>
          </div>
          <div className="graphic_c_text_1">
            <p data-aos="fade-right" className="gr_c_p">
              {t("graphic_design_course.2")} 
            </p>
            <p data-aos="fade-right" uk-margin>
              <a href="#modal-media-image" uk-toggle="toggle:true">
              {t("graphic_design_course.3")}
              </a>
            </p>
           </div>
            <div
              id="modal-media-image"
              className="uk-flex-top"
              uk-modal="modal:true"
            >
              <div
                className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical"
                uk-modal-dialog
                uk-modal-body
              >
                <button
                  className="uk-modal-close-outside"
                  type="button"
                  uk-close="close:true"
                ></button>
                <img src={images1} width="700" height="1200" alt="" />
              </div>
            </div>
         

          <div className="gr_c_text_images_container">
            <div className="gr_c_imges">
              <div className="gr_c_text_container2">
                <div className="gr_c_titel2">
                  <h2 data-aos="fade-right" className="main-titel_service">{t("Why_study_with_us.01")}</h2>
                </div>
                <div className="gr_c_text_cont2">
                  <ul>
                    <li data-aos="fade-right" className="list_gr_c">
                    {t("Why_study_with_us.1")}
                    </li>
                    <li data-aos="fade-right" className="list_gr_c">
                    {t("Why_study_with_us.2") }
                    </li>
                    <li data-aos="fade-right" className="list_gr_c">
                    {t("Why_study_with_us.3") } 
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="our_trainer_container">
            <div className="trainer_images_text">
              <div className="trainer">
                <img src={trainerImages} className="trainerImages" alt="" />
                   <div className="text_block_trainer">
                   <h3 data-aos="fade-right" className="trainer_name">{t("Name.1")}</h3>
                <p data-aos="fade-right" className="profession_trainer">
                  <span data-aos="fade-right"> {t("ux_curse_titel.2")}:&nbsp; <a
                    href="https://www.behance.net/tsovzakar2dc0"
                    target="blank"
                  >
                     https://www.behance.net/tsovzakar
                  </a>{" "}</span>
                 
                </p>
                   </div>
               
              </div>
              <div className="our_trainer_text">
                <div className="titel_t_o-gr">
                  <h2 data-aos="fade-right" className="who-will-be-trainer">{t("Gr_curs.1")}</h2>
                </div>
                <ul>
                  <li data-aos="fade-right" className="list_gr_c">
                  {t("Gr_curs.2")}
                  </li>
                  <li data-aos="fade-right" className="list_gr_c">
                  {t("Gr_curs.3")}
                  </li>
                  <li data-aos="fade-right" className="list_gr_c">
                  {t("Gr_curs.4")}
                  </li>
                  <li data-aos="fade-right" className="list_gr_c">
                  {t("Gr_curs.5")}
                  </li>
                  <li data-aos="fade-right" className="list_gr_c">
                  {t("Gr_curs.6")}
                  </li>
                  <li data-aos="fade-right" className="list_gr_c">
                  {t("Gr_curs.7")}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="studets_works">
            <div className="work_image">
              <div className="work_titel_gr_c">
                <h2 data-aos="fade-right" className=" main-titel_service">{t("Gr_curs.8")}</h2>
              </div>
               {
                <>
                  {
                    work_cover_arr.map((item)=>{
                      return (
                        <img  key={item.id} src={item.img} alt="" style={{cursor:"pointer"}}  onClick={() => {
                          setactiveSlider(item.id);
                          openModal() 
                                       
                      }} />
                      )
                      
                    })
                  }
                </>
               }
             
              <div className="btn_container_gr_curse">
                
                  <NavLink   data-aos="fade-right"   
                    //  onMouseEnter={handleMouseEnter}
                    //  onMouseLeave={handleMouseLeave}   
                   onMouseMove={handleMouseMove} 
                   className="our-work-btn" to="/students-work">                
                     <span> {t("Gr_curs.9")}</span>
                     <div  
                      className="animation_div"
                      style={{
                      position: 'absolute',
                      top: `${y}`,
                      left: `${x}`,
                      width: '0',
                      height: '0',
                      background: 'orangered',
                      borderRadius:'50%',
                      transform: `translate(-50%, -50%)`,
                      transition: '1s'
                    }}/>     
                 
                     </NavLink>

                 
              </div>
            </div>
          </div>
          <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                     >        
                   

                   <div className="slider_container_or_btn">
                    <div className="clos_btn_container">
                    <MdClose onClick={closeModal} className="close_path"/>
                    </div>
                   
                   <ImageGallery disableThumbnailScroll={false}  thumbnailPosition = {biggerThan ? "bottom" : "left"} items = {activeSlider}/>
                   </div>
                  
      </Modal>
        </div>
        <div className="register_container" id="hide_mode">
        <div className={alertshow ? "alert_container show" : "alert_container"} id="sucsess_mode">
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              <button 
                type="button" 
                className="close" 
                aria-label="Close"
                onClick={()=> HandleRefresh()}
                >
                <span aria-hidden="true">×</span>
              </button>
              <span className="alert-inner--icon">
                <AiFillLike />
              </span>
              <span className="alert-inner--text ml-1">
                <strong>Success!</strong> 
                Thanks for filling out our form!
              </span>
            </div>
        </div>
        <form className="row g-3 register" onSubmit={handleSubmit(onSubmit)} ref={formCurr}>
          <div className="reg_titel_container">
            <div className="titel_register">
              <h1 className="titele_r">
                {t("Register_1.00")} <span className="t_h1"> {t("Register_1.011")}</span>
              </h1>
              <h1 className="titele_r">
                {t("Register_1.2")} <span className="t_h1"> {t("Register_1.03")}</span>
              </h1>
              <h3 className="reg_n"> {t("Register_1.3")}</h3>
            </div>
          </div>
          <div className="cont_user-name_lastname">
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.4")}
              </span>
              <div className={errors?.user_name?.type === "required" ? "form-block  has-error" : "form-block"}  >
                <input placeholder="Name" className="form-control" name="user_name" {...register("user_name", { required: true })} />
                <p className="error-info" >This field is required</p>
              </div>
            </div>
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.6")}
              </span>
              <div className={errors?.user_surname?.type === "required" ? "form-block has-error" : "form-block"}  >
                <input placeholder="Surname" className="form-control" name="user_surname" {...register("user_surname", { required: true })} />
                <p className="error-info" >This field is required</p>
              </div>
            </div>
          </div>
          <div className="cont_user-name_lastname">
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.7")}
              </span>
              <div className="input-group">
                <div className={errors?.user_email?.type === "required" || errors?.user_email?.type === "pattern" ? "mail_inline form-block has-error" : "mail_inline form-block"}  >
                  <span className="input-group-text" id="inputGroupPrepend2">
                    @
                  </span>
                  <input placeholder="Email" className="form-control" name="user_email" {...register("user_email", { required: true, pattern: /^\S+@\S+$/i })} />
                  {errors?.user_email?.type === "pattern" ? <p className="error-info email-info" >invalid Email</p> :
                    <p className="error-info" >This field is required</p>
                  }
                </div>
              </div>
            </div>
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.9")}
              </span>
              <div className="input-group">
                <div className={errors?.phone_number?.type === "required" ? "form-block has-error" : "form-block"}  >
                  <input    type="number" placeholder="Phone Number" className="form-control" name="phone_number" {...register("phone_number", { required: true })} />
                  <p className="error-info" >This field is required</p>
                </div>
              </div>
            </div>
          </div>

          <div className="cont_user-name_lastname">
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.11")}
              </span>
              <div className={errors?.city?.type === "required" ? "form-block has-error" : "form-block"}  >
                <input placeholder="City" className="form-control" name="city" {...register("city", { required: true })} />
                <p className="error-info" >This field is required</p>
              </div>
            </div>
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.13")}
              </span>
              <div className={errors?.state?.type === "required" ? "form-block has-error" : "form-block"}  >
                <input placeholder="state" className="form-control" name="state" {...register("state", { required: true })} />
                <p className="error-info" >This field is required</p>
              </div>
            </div>
          </div>
          <div className="cont_user-name_lastname">
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.15")}
              </span>
              <div className={errors?.course?.type === "required" ? "form_block sellect_section has-error" : "form_block"}  >
                <Controller
                  name="course"
                  control={control}
                  rules={{ required: 'Select Course' }}
                  render={({ field }) => (
                    <Select
                      className=" register_sellect"
                      options={CoursesOption}
                      placeholder="Select Course"
                      {...field}
                    />
                  )}
                />
                <p className="error-info" >This field is required</p>
              </div>
            </div>
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.16")}
              </span>
              <div className={errors?.course_type?.type === "required" ? "form_block sellect_section has-error" : "form_block"}  >
                <Controller
                  name="course_type"
                  control={control}
                  rules={{ required: 'Select Course' }}
                  render={({ field }) => (
                    <Select
                      className=" register_sellect"
                      options={CoursesType}
                      placeholder="Select Course"
                      {...field}
                    />
                  )}
                />
                <p className="error-info" >This field is required</p>
              </div>
            </div>
          </div>
          <div className="form-floating textarea_floating">
            <span  className="form-label">
              Comment
            </span>
            <textarea name="comment" placeholder="Message" className="form-control textarea_input" />
            {/* <div className={errors?.comment?.type === "required" ? "form-block  has-error" : "form-block"}  >
              <p className="error-info" >This field is required</p>
            </div> */}
          </div>
          <div className="col-12 buton_register">
            <button disabled={disabledBtn} className="btn_join" onMouseMove={handleMouseMove}>
              <div
                className="animation_div"
                style={{
                  position: 'absolute  ',
                  top: `${y}`,
                  left: `${x}`,
                  width: '0',
                  height: '0',
                  background: 'black',
                  borderRadius: '50%',
                  transform: `translate(-50%, -50%)`,
                  transition: '1s'
                }} />
              <span>{t("Register_1.18")}</span>
            </button>
          </div>
        </form>
      </div>
      </div>
      <Footer />
    </>
  );
}

export default GraphicCurse;
