import React, { useEffect } from "react";
import "../Webdesign/webDes.css";
import laptop from "../../assets/images/Web-design-laptop.png";
// import line from "../src/imges/Line 1.png";
// import webTemplates from "../src/imges/Web-design-.-templates.png";
// import webdesImges from "../src/imges/Rectangle 20 (2).png";
// import frame from "../src/imges/Frame (1).png";

import Nvabar from "../Navbar/Nvabar";
import Footer from "../page/Footer/Footer";
import JointTeam from "../Jointeam/JoinTeam";
import { useTranslation } from "react-i18next";
import Aos from "aos";

function WebDesign() {
  const {t} = useTranslation()
  useEffect(()=>{
    Aos.init({duration:500})
 })
  return (
    <>
      <Nvabar />

      <div className="web-des"></div>

      <div className="webdes-text">
        <div className="icon-text">
          <span data-aos="fade-right" className="webDes-titel"> {t("Sevices.3")} </span>
        </div>
        <div className="web_des_text_container">
          <p data-aos="fade-right" className="row-wd">
                {t("WebDesign.1")}
          </p>
        </div>
      </div>
      <div className="web-laptop">
        <img src={laptop} alt="" className="laptop" />
      </div>
      <div className="des_text">
        <p data-aos="fade-right" className="row-wd">
        {t("WebDesign.2")}         
        </p>
      </div>
      <div className="line-black"></div>
      <JointTeam />
      <Footer />
    </>
  );
}

export default WebDesign;
