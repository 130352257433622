import React from "react";
import "../BookDesign/bookdes.css";

// import bookdes from "../src/imges/Book-design-2.png";
import { useTranslation } from "react-i18next";
import Nvabar from "../Navbar/Nvabar";
import JointTeam from "../Jointeam/JoinTeam";
import Footer from "../page/Footer/Footer";
import book_cover from '../../assets/images/bookdesigncover.png'
function BookDesign() {
  const { t } = useTranslation();

  return (
    <>
      <Nvabar />
      <div></div>
      <div className="book-design">  </div>
       
    
      <img src={book_cover} className="book_cover_mobile" alt="" />
      <div className="smm-text">
        <div className="icon-text-book">
          <h2 className="graphic-text"> {t("BookDesign.1")} </h2>
        </div>
        <div className="text-p_b">
          <p className="row-b">
              {t("BookDesign.2")}
          </p>
        </div>
      </div>
      <div className="imges-section">
        <div className="about-books">
          <div className="book-l">
            <h2 className="book book-info">
            {t("BookDesign.3")}
            </h2>
          </div>
          <div className="book-text">
            <p className="book_p_t">
            {t("BookDesign.4")}
            </p>
          </div>
        </div>
        <JointTeam />

      </div>

      <div className="book-des">
        <div className="bookdes">
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BookDesign;
