import React, { Suspense } from 'react';
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import  './i18next';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
     <Suspense >
          <App />
     </Suspense> 
  
    </BrowserRouter>
  </React.StrictMode>
);
