import shoper1 from "../WorkSt/shoper (1).png";
import shoper2 from "../WorkSt/shoper (2).png";
import shoper3 from "../WorkSt/shoper (3).png";
import shoper4 from "../WorkSt/shoper (4).png";
import shoper5 from "../WorkSt/shoper (5).png";
import shoper6 from "../WorkSt/shoper (6).png";
import shoper7 from "../WorkSt/shoper (7).png";
import shoper8 from "../WorkSt/shoper (8).png";
import shoper9 from "../WorkSt/shoper (9).png";
import shoper10 from "../WorkSt/shoper (10).png";
import shoper11 from "../WorkSt/shoper (11).png";
import shoper12 from "../WorkSt/shoper (12).png";
import shoper13 from "../WorkSt/shoper (3).png";
import club1 from "../WorkSt/club1.png";
import club2 from "../WorkSt/club2.png";
import club3 from "../WorkSt/club3.png";
import club4 from "../WorkSt/club4.png";
import medicos1 from "../WorkSt/medicos1.png";
import medicos2 from "../WorkSt/medicos2.png";
import medicos3 from "../WorkSt/medicos3.png";
import medicos4 from "../WorkSt/medicos4.png";
import hassina1 from "../WorkSt/hassina (1).png";
import hassina2 from "../WorkSt/hassina (2).png";
import hassina3 from "../WorkSt/hassina (3).png";
import hassina4 from "../WorkSt/hassina (4).png";
import hassina5 from "../WorkSt/hassina (5).png";
import hassina6 from "../WorkSt/hassina (6).png";
import hassina7 from "../WorkSt/hassina (7).png";
import hassina8 from "../WorkSt/hassina (8).png";
import hassina9 from "../WorkSt/hassina (9).png";
import hassina10 from "../WorkSt/hassina (10).png";
import hassina11 from "../WorkSt/hassina (11).png";
import tallest1 from "../WorkSt/tallest (1).png";
import tallest2 from "../WorkSt/tallest (2).png";
import tallest3 from "../WorkSt/tallest (3).png";
import tallest4 from "../WorkSt/tallest (4).png";
import tallest5 from "../WorkSt/tallest (5).png";
import tallest6 from "../WorkSt/tallest (6).png";
import tallest7 from "../WorkSt/tallest (7).png";
import tallest8 from "../WorkSt/tallest (8).png";
import tallest9 from "../WorkSt/tallest (9).png";
import tallest10 from "../WorkSt/tallest (10).png";
import tallest11 from "../WorkSt/tallest (11).png";
import tallest12 from "../WorkSt/tallest (12).png";
import tallest13 from "../WorkSt/tallest (13).png";
import avanta1 from "../WorkSt/avanta (1).png";
import avanta2 from "../WorkSt/avanta (2).png";
import avanta3 from "../WorkSt/avanta (3).png";
import avanta4 from "../WorkSt/avanta (4).png";
import avanta5 from "../WorkSt/avanta (5).png";
import avanta6 from "../WorkSt/avanta (6).png";
import avanta7 from "../WorkSt/avanta (7).png";
import avanta8 from "../WorkSt/avanta (8).png";
import avanta9 from "../WorkSt/avanta (9).png";
import avanta10 from "../WorkSt/avanta (10).png";
import avanta11 from "../WorkSt/avanta (11).png";
import avanta12 from "../WorkSt/avanta (12).png";
import alias1 from "../WorkSt/alias (1).png";
import alias2 from "../WorkSt/alias (2).png";
import alias3 from "../WorkSt/alias (3).png";
import alias4 from "../WorkSt/alias (4).png";
import alias5 from "../WorkSt/alias (5).png";
import alias6 from "../WorkSt/alias (6).png";
import alias7 from "../WorkSt/alias (7).png";
import alias8 from "../WorkSt/alias (8).png";
import alias9 from "../WorkSt/alias (9).png";
import alias10 from "../WorkSt/alias (10).png";
import book1 from "../WorkSt/book (1).png";
import book2 from "../WorkSt/book (2).png";
import book3 from "../WorkSt/book (3).png";
import book4 from "../WorkSt/book (4).png";
import book5 from "../WorkSt/book (5).png";
import book6 from "../WorkSt/book (6).png";
import book7 from "../WorkSt/book (7).png";
import book8 from "../WorkSt/book (8).png";
import book9 from "../WorkSt/book (9).png";
import book10 from "../WorkSt/book (10).png";
import book11 from "../WorkSt/book (11).png";
import book12 from "../WorkSt/book (12).png";
import book13 from "../WorkSt/book (13).png";
import book14 from "../WorkSt/book (14).png";
import implant1 from "../WorkSt/implant2 (1).png";
import implant2 from "../WorkSt/implant2 (2).png";
import implant3 from "../WorkSt/implant2 (3).png";
import implant4 from "../WorkSt/implant2 (4).png";
import implant5 from "../WorkSt/implant2 (5).png";
import implant6 from "../WorkSt/implant2 (6).png";
import implant7 from "../WorkSt/implant2 (7).png";
import implant8 from "../WorkSt/implant2 (8).png";
import implant9 from "../WorkSt/implant2 (9).png";
import implant10 from "../WorkSt/implant2 (10).png";
import implant11 from "../WorkSt/implant2 (11).png";
import implant12 from "../WorkSt/implant2 (12).png";
import implant13 from "../WorkSt/implant2 (13).png";
import implant14 from "../WorkSt/implant2 (14).png";
import implat15 from "../WorkSt/implant3 (1).png";
import implat16 from "../WorkSt/implant3 (2).png";
import implat17 from "../WorkSt/implant3 (3).png";
import implat18 from "../WorkSt/implant3 (4).png";
import implat19 from "../WorkSt/implant3 (5).png";
import implat20 from "../WorkSt/implant3 (6).png";
import implat21 from "../WorkSt/implant3 (7).png";
import implat22 from "../WorkSt/implant3 (8).png";
import implat23 from "../WorkSt/implant3 (9).png";
// import implat24 from '../WorkSt/implant3 (10).png'

import nur1 from "../WorkSt/nur (1).png";
import nur2 from "../WorkSt/nur (2).png";
import nur3 from "../WorkSt/nur (3).png";
import nur4 from "../WorkSt/nur (4).png";
import nur5 from "../WorkSt/nur (5).png";
import nur6 from "../WorkSt/nur (6).png";
import nur7 from "../WorkSt/nur (7).png";
import nur8 from "../WorkSt/nur (8).png";
import nur9 from "../WorkSt/nur (9).png";
import nur10 from "../WorkSt/nur (10).png";
import nur11 from "../WorkSt/nur (11).png";
import nur12 from "../WorkSt/nur (12).png";
import nur13 from "../WorkSt/nur (13).png";
import nur14 from "../WorkSt/nur (14).png";
import bookChristmas1 from "../WorkSt/bookChristmas (1).png";
import bookChristmas2 from "../WorkSt/bookChristmas (2).png";
import bookChristmas3 from "../WorkSt/bookChristmas (3).png";
import bookChristmas4 from "../WorkSt/bookChristmas (4).png";
import bookChristmas5 from "../WorkSt/bookChristmas (5).png";
import bookChristmas6 from "../WorkSt/bookChristmas (6).png";
import bookChristmas7 from "../WorkSt/bookChristmas (7).png";
import bookChristmas8 from "../WorkSt/bookChristmas (8).png";
import bookChristmas9 from "../WorkSt/bookChristmas (9).png";
import bookChristmas10 from "../WorkSt/bookChristmas (10).png";
import bookChristmas11 from "../WorkSt/bookChristmas (11).png";
import bookChristmas12 from "../WorkSt/bookChristmas (12).png";
import bookChristmas13 from "../WorkSt/bookChristmas (13).png";
import bookChristmas14 from "../WorkSt/bookChristmas (14).png";
import bookChristmas15 from "../WorkSt/bookChristmas (15).png";
import bookChristmas16 from "../WorkSt/bookChristmas (16).png";
import bookChristmas17 from "../WorkSt/bookChristmas (17).png";
import bookChristmas18 from "../WorkSt/bookChristmas (18).png";
import bookChristmas19 from "../WorkSt/bookChristmas (19).png";
import bookChristmas20 from "../WorkSt/bookChristmas (20).png";
import xPlant1 from "../WorkSt/xPlant  (1).png";
import xPlant2 from "../WorkSt/xPlant  (2).png";
import xPlant3 from "../WorkSt/xPlant  (3).png";
import xPlant4 from "../WorkSt/xPlant  (4).png";
import xPlant5 from "../WorkSt/xPlant  (5).png";
import kahuqmall1 from "../WorkSt/kahuqmall (1).png";
import kahuqmall2 from "../WorkSt/kahuqmall (2).png";
import kahuqmall3 from "../WorkSt/kahuqmall (3).png";
import kahuqmall4 from "../WorkSt/kahuqmall (4).png";
import kahuqmall5 from "../WorkSt/kahuqmall (5).png";
import kahuqmall6 from "../WorkSt/kahuqmall (6).png";
import kahuqmall7 from "../WorkSt/kahuqmall (7).png";
import kahuqmall8 from "../WorkSt/kahuqmall (8).png";
import kahuqmall9 from "../WorkSt/kahuqmall (9).png";
import kahuqmall10 from "../WorkSt/kahuqmall (10).png";
import kahuqmall11 from "../WorkSt/kahuqmall (11).png";
import kahuqmall12 from "../WorkSt/kahuqmall (12).png";
import kahuqmall13 from "../WorkSt/kahuqmall (13).png";
import kahuqmall14 from "../WorkSt/kahuqmall (14).png";
import kahuqmall15 from "../WorkSt/kahuqmall (15).png";
import Gohar1 from "../WorkSt/Gohar (1).png";
import Gohar2 from "../WorkSt/Gohar (2).png";
import Gohar3 from "../WorkSt/Gohar (3).png";
import myBooks1 from "../WorkSt/myBooks (1).png";
import myBooks2 from "../WorkSt/myBooks (2).png";
import myBooks3 from "../WorkSt/myBooks (3).png";
import myBooks4 from "../WorkSt/myBooks (4).png";
import myBooks5 from "../WorkSt/myBooks (5).png";
import myBooks6 from "../WorkSt/myBooks (6).png";
import myBooks7 from "../WorkSt/myBooks (7).png";
import myBooks8 from "../WorkSt/myBooks (8).png";
import myBooks9 from "../WorkSt/myBooks (9).png";
import myBooks10 from "../WorkSt/myBooks (10).png";
import myBooks11 from "../WorkSt/myBooks (11).png";
import myBooks12 from "../WorkSt/myBooks (12).png";
import myBooks13 from "../WorkSt/myBooks (13).png";
import vmjew1 from "../WorkSt/VMjewellery (1).png";
import vmjew2 from "../WorkSt/VMjewellery (2).png";
import vmjew3 from "../WorkSt/VMjewellery (3).png";
import vmjew4 from "../WorkSt/VMjewellery (4).png";
import ledy1 from "../WorkSt/ledy (1).png";
import ledy2 from "../WorkSt/ledy (2).png";
import ledy3 from "../WorkSt/ledy (3).png";
import ledy4 from "../WorkSt/ledy (4).png";
import ledy5 from "../WorkSt/ledy (5).png";
import glowing1 from "../WorkSt/glowing (1).png";
import glowing2 from "../WorkSt/glowing (2).png";
import glowing3 from "../WorkSt/glowing (3).png";
import glowing4 from "../WorkSt/glowing (4).png";
import glowing5 from "../WorkSt/glowing (5).png";
import glowing6 from "../WorkSt/glowing (6).png";
import glowing7 from "../WorkSt/glowing (7).png";
import glowing8 from "../WorkSt/glowing (8).png";
import black1 from "../WorkSt/Black (1).png";
import black2 from "../WorkSt/Black (2).png";
import black3 from "../WorkSt/Black (3).png";
import black4 from "../WorkSt/Black (4).png";
import black5 from "../WorkSt/Black (5).png";
import black6 from "../WorkSt/Black (6).png";
import everes1 from "../WorkSt/everest  (1).png";
import everes2 from "../WorkSt/everest  (2).png";
import everes3 from "../WorkSt/everest  (3).png";
import everes4 from "../WorkSt/everest  (4).png";
import everes5 from "../WorkSt/everest  (5).png";
import everes6 from "../WorkSt/everest  (6).png";
import onbord1 from "../WorkSt/OnBoard-brending1.jpg";
import onbord2 from "../WorkSt/OnBoard-brending-2.jpg";
import onbord3 from "../WorkSt/OnBoard-brending-3.jpg";
import onbord4 from "../WorkSt/OnBoard-brending-4.jpg";
import onbord5 from "../WorkSt/OnBoard-brending-5.jpg";
import onbord6 from "../WorkSt/OnBoard-brending-6.jpg";
import onbord7 from "../WorkSt/OnBoard-brending-7.jpg";
import onbord8 from "../WorkSt/OnBoard-brending-8.jpg";
import onbord9 from "../WorkSt/OnBoard-brending-9.jpg";
import onbord10 from "../WorkSt/OnBoard-brending-10.jpg";
import onbord11 from "../WorkSt/OnBoard-brending-11.jpg";
import onbord12 from "../WorkSt/OnBoard-brending12.jpg";
import onbord13 from "../WorkSt/OnBoard-brending13.jpg";
import onbord14 from "../WorkSt/OnBoard-brending14.jpg";
import onbord15 from "../WorkSt/OnBoard-brending15.jpg";
import onbord16 from "../WorkSt/OnBoard-brending16.jpg";
import onbord17 from "../WorkSt/OnBoard-brending17.jpg";
import onbord18 from "../WorkSt/OnBoard-brending18.jpg";
import onbord19 from "../WorkSt/OnBoard-brending19.jpg";
import onbord20 from "../WorkSt/OnBoard-brending20.jpg";
import onbord21 from "../WorkSt/OnBoard-brending21.jpg";
import onbord22 from "../WorkSt/OnBoard-brending22.jpg";
import onbord23 from "../WorkSt/OnBoard-brending23.jpg";
import onbord24 from "../WorkSt/OnBoard-brending24.jpg";
import msLogo1 from "../WorkSt/MS-Logo-2.jpg";
import msLogo2 from "../WorkSt/MS-Logo-3.jpg";
import irinik from "../WorkSt/Iriknak-logo mockup (1).jpg";
export const msLogo = [{ img: msLogo1 }, { img: msLogo2 }];
export const irinik1 = [{ img: irinik }];
export const shoper = [
  { img: shoper1 },
  { img: shoper2 },
  { img: shoper3 },
  { img: shoper4 },
  { img: shoper5 },
  { img: shoper6 },
  { img: shoper7 },
  { img: shoper8 },
  { img: shoper9 },
  { img: shoper10 },
  { img: shoper11 },
  { img: shoper12 },
  { img: shoper13 },
];

export const club = [
  { img: club1 },
  { img: club2 },
  { img: club3 },
  { img: club4 },
];

export const medicos = [
  { img: medicos1 },
  { img: medicos2 },
  { img: medicos3 },
  { img: medicos4 },
];

export const hassina = [
  { img: hassina1 },
  { img: hassina2 },
  { img: hassina3 },
  { img: hassina4 },
  { img: hassina5 },
  { img: hassina6 },
  { img: hassina7 },
  { img: hassina8 },
  { img: hassina9 },
  { img: hassina10 },
  { img: hassina11 },
];

export const tallest = [
  { img: tallest1 },
  { img: tallest2 },
  { img: tallest3 },
  { img: tallest4 },
  { img: tallest5 },
  { img: tallest6 },
  { img: tallest7 },
  { img: tallest8 },
  { img: tallest9 },
  { img: tallest10 },
  { img: tallest11 },
  { img: tallest12 },
  { img: tallest13 },
];

export const avanta = [
  { img: avanta1 },
  { img: avanta2 },
  { img: avanta3 },
  { img: avanta4 },
  { img: avanta5 },
  { img: avanta6 },
  { img: avanta7 },
  { img: avanta8 },
  { img: avanta9 },
  { img: avanta10 },
  { img: avanta11 },
  { img: avanta12 },
];
export const alias = [
  { img: alias1 },
  { img: alias2 },
  { img: alias3 },
  { img: alias4 },
  { img: alias5 },
  { img: alias6 },
  { img: alias7 },
  { img: alias8 },
  { img: alias9 },
  { img: alias10 },
];

export const book = [
  { img: book1 },
  { img: book2 },
  { img: book3 },
  { img: book4 },
  { img: book5 },
  { img: book6 },
  { img: book7 },
  { img: book8 },
  { img: book9 },
  { img: book10 },
  { img: book11 },
  { img: book12 },
  { img: book13 },
  { img: book14 },
  { img: implant1 },
  { img: implant2 },
  { img: implant3 },
  { img: implant4 },
  { img: implant5 },
  { img: implant6 },
  { img: implant7 },
  { img: implant8 },
  { img: implant9 },
  { img: implant10 },
  { img: implant11 },
  { img: implant12 },
  { img: implant13 },
  { img: implant14 },
  { img: implat15 },
  { img: implat16 },
  { img: implat17 },
  { img: implat18 },
  { img: implat19 },
  { img: implat20 },
  { img: implat21 },
  { img: implat22 },
  { img: implat23 },
  // {img:implat24}
];

export const nur = [
  { img: nur1 },
  { img: nur2 },
  { img: nur3 },
  { img: nur4 },
  { img: nur5 },
  { img: nur6 },
  { img: nur7 },
  { img: nur8 },
  { img: nur9 },
  { img: nur10 },
  { img: nur11 },
  { img: nur12 },
  { img: nur13 },
  { img: nur14 },
];

export const bookChristmas = [
  { img: bookChristmas1 },
  { img: bookChristmas2 },
  { img: bookChristmas3 },
  { img: bookChristmas4 },
  { img: bookChristmas5 },
  { img: bookChristmas6 },
  { img: bookChristmas7 },
  { img: bookChristmas8 },
  { img: bookChristmas9 },
  { img: bookChristmas10 },
  { img: bookChristmas11 },
  { img: bookChristmas12 },
  { img: bookChristmas13 },
  { img: bookChristmas14 },
  { img: bookChristmas15 },
  { img: bookChristmas16 },
  { img: bookChristmas17 },
  { img: bookChristmas18 },
  { img: bookChristmas19 },
  { img: bookChristmas20 },
];

export const xPlant = [
  { img: xPlant1 },
  { img: xPlant2 },
  { img: xPlant3 },
  { img: xPlant4 },
  { img: xPlant5 },
];

export const kahuqmall = [
  { img: kahuqmall1 },
  { img: kahuqmall2 },
  { img: kahuqmall3 },
  { img: kahuqmall4 },
  { img: kahuqmall5 },
  { img: kahuqmall6 },
  { img: kahuqmall7 },
  { img: kahuqmall8 },
  { img: kahuqmall9 },
  { img: kahuqmall10 },
  { img: kahuqmall11 },
  { img: kahuqmall12 },
  { img: kahuqmall13 },
  { img: kahuqmall14 },
  { img: kahuqmall15 },
];

export const photoG = [{ img: Gohar1 }, { img: Gohar2 }, { img: Gohar3 }];

export const myBooks = [
  { img: myBooks1 },
  { img: myBooks2 },
  { img: myBooks3 },
  { img: myBooks4 },
  { img: myBooks5 },
  { img: myBooks6 },
  { img: myBooks7 },
  { img: myBooks8 },
  { img: myBooks9 },
  { img: myBooks10 },
  { img: myBooks11 },
  { img: myBooks12 },
  { img: myBooks13 },
];

export const vmjew = [
  { img: vmjew1 },
  { img: vmjew2 },
  { img: vmjew3 },
  { img: vmjew4 },
];

export const ledy = [
  { img: ledy1 },
  { img: ledy2 },
  { img: ledy3 },
  { img: ledy4 },
  { img: ledy5 },
];

export const glowing = [
  { img: glowing1 },
  { img: glowing2 },
  { img: glowing3 },
  { img: glowing4 },
  { img: glowing5 },
  { img: glowing6 },
  { img: glowing7 },
  { img: glowing8 },
];

export const black = [
  { img: black1 },
  { img: black2 },
  { img: black3 },
  { img: black4 },
  { img: black5 },
  { img: black6 },
];

export const everest = [
  { img: everes1 },
  { img: everes2 },
  { img: everes3 },
  { img: everes4 },
  { img: everes5 },
  { img: everes6 },
];

export const onBoard = [
  { img: onbord1 },
  { img: onbord2 },
  { img: onbord3 },
  { img: onbord4 },
  { img: onbord5 },
  { img: onbord6 },
  { img: onbord7 },
  { img: onbord8 },
  { img: onbord9 },
  { img: onbord10 },
  { img: onbord11 },
  { img: onbord12 },
  { img: onbord13 },
  { img: onbord14 },
  { img: onbord15 },
  { img: onbord16 },
  { img: onbord17 },
  { img: onbord18 },
  { img: onbord19 },
  { img: onbord20 },
  { img: onbord21 },
  { img: onbord22 },
  { img: onbord23 },
  { img: onbord24 },
];
