import React, { useState } from "react";
import "../OurProject/ourProject.css";
import {  project_our1} from "../../imges";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import Footer from "../page/Footer/Footer";
import Navbar from "../Navbar/Nvabar";
import { MdClose } from "react-icons/md";
import Modal from 'react-modal'
function OurProject() {
  const [closModal, setClosModal] = useState(false);
  const [id, setId] = useState(0);
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(false);
   const [activSlider,setActivSlidr] = useState([])
  
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function closeModal() {
  setIsOpen(false);
}
function openModal() {
  setIsOpen(true);
}
  return (
    <>
      <div>
      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          >        
            <div className="carusel-contaner-our" >
            <div className="clos_icon_container">   <MdClose onClick={closeModal} className="close_path"/></div>
            <Carousel
              showArrows={true}
              selectedItem={id}
              className="caruselNew"
              showThumbs={true}
            
            >
              <div className="project_container">
                  <div className="text-or-btn">
                    <div className="title">
                      {" "}
                      <h2 className="work-L">Shopper online shop</h2>
                    </div>
                    <div
                      className="to_close"
                      onClick={() => {
                        setClosModal(closModal);
                        document.body.style.overflow = "scroll-x"
                      }}
                    >             
                    </div>
                  </div>{" "}
                  
                  {activSlider.map((imges) => {
                      if(activSlider.length <= 0 ){
                        return (
                          <h1>Iages not faound</h1>
                        )
                    }
                    return (
                          <div className="carusel-iamges-container">  
                      <img src={imges.img} className="" alt="images" />
                      </div>
                    );
                  })}
              </div>
            </Carousel>
          </div>                      
      </Modal>
      </div>
         <Navbar />
      <div className="ourPr_title">
        <div className="icon_titel_c">
          <h1 className="titele_text">{t("HOME.3")} </h1>
        </div>
      </div>
        
        {
          project_our1.map((data,i)=> {
            return(
              <>
          <div>
            <img 
               id={data.id}
               src={data.img} alt="images" 
               className="work-imges"
               onClick={() => {
                 setClosModal(!closModal);
                   setId(data.id);
                   setActivSlidr(data.id)
                   openModal()
                 document.body.overflowX = 'hidden'

               }}
               />
            
         </div>
               </>
            )
           
          })
        }
        
  
      <Footer />
    </>
  );
}

export default OurProject;
