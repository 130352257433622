import ".//scool.css";
import imgescool from "../../assets/images/Rectangle 18 (1).png";
import Nvabar from "../Navbar/Nvabar";
import Footer from "../page/Footer/Footer";
import { useTranslation } from "react-i18next";
function School() {
  const {t} = useTranslation()
  return (
    <>
      <div>
        <Nvabar />
      </div>
      <div className="scool-one-img">
        <div className="scool-page"></div>
      </div>
      <div className="scool-text">
        <div className="titel_scool">
          <h2 className="main-titel_service">  {t("School.1")} </h2>
        </div>
        <div className="text-sectionOune">
          <p className="row-sc">
             {t("School.2")}
          </p>
        </div>
      </div>
      <div className="img-section">
        <div className="rectangle">
          <img src={imgescool} className="rectangle" alt="" />
        </div>
      </div>
      <div className="scool-imge-page">
        <a  href='/graphic-course'>
        <div className="scoolImge"> 
         
            <div className="btn-container-school">
              <div className="btn-cont-2-s">

              </div>
            </div>   
           
        </div> 
        </a>
      </div>
      <div className="img-section">
        <div className="rectangle-0">
          <img src={imgescool} className="rectangle-0" alt="" />
        </div>
      </div>
      <div className="scool-imge-page">
        <div className="smm-scool"></div>
      </div>
      <div className="img-section">
        <div className="rectangle-0">
          <img src={imgescool} className="rectangle-0" alt="" />
        </div>
      </div>
      <div className="scool-imge-page"> 
       <a  href='/ui-ux-course'>
        <div className="scool-uiux">
        <div className="btn-container-school">
              <div className="btn-cont-2-s">
                
              </div>
            </div>
        </div>  
        </a>
      </div>
      <div className="img-section">
        <div className="rectangle-0">
          <img src={imgescool} className="rectangle-0" alt="" />
        </div>
      </div>
      <div className="scool-imge-page">
        <div className="scool-end">
        <div className="btn-container-school"></div>
             
            
        </div>
      </div>
      <Footer />
    </>
  );
}

export default School;
