import React, { useEffect } from "react";
import "../Home/home.css";
import logoHome from "../../assets/images/Logo.png";
import { NavLink } from "react-router-dom/dist";
import elipse from "../../assets/images/Ellipse 4.png";
import menu from "../../assets/images/Logo/menu (5).png";
import { useTranslation } from "react-i18next"
import { LenguageSwitcher } from "../LenguageSwitcher/LenguageSwitcher";
import Aos from "aos";
import { MdClose } from "react-icons/md";
import { AiOutlineInstagram } from "react-icons/ai"
import { FaFacebookF } from "react-icons/fa"
import { FaTwitter } from 'react-icons/fa'
import { BsBehance } from "react-icons/bs"
import { FaPinterestP } from "react-icons/fa"
import { BiLogoLinkedin } from "react-icons/bi"


function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init({ duration: 500 })
  }, [])

  return (
    <>
      <div className="response_nav_home">
        <div className="logo_home">
          <NavLink className="text_m" to="/">
            <img src={logoHome} className="logoBalck" alt="" />
          </NavLink>
        </div>
        <div className="buton">
          <img
            src={menu}
            className="menu"
            alt=""
            uk-toggle="target: #offcanvas-overlay"
          />
          <div id="offcanvas-overlay" uk-offcanvas="overlay: true">
            <div className="uk-offcanvas-bar">
              <button className="uk-offcanvas-close" type="button">
                <MdClose />
              </button>
              <NavLink className="text_m" to="/">
                <img src={logoHome} className="logoBalck" alt="" />
              </NavLink>
              <div className="uk-width-1-2@s uk-width-2-5@m">
                <ul className="uk-nav-default" uk-nav="multiple: true">
                  <li className="uk-active"><a href="/#"> <span>{t('HOME.1')}</span></a></li>
                  <li className="uk-parent">
                    <a href="/#"> <span>{t('Sevices.5')}</span> <span uk-nav-parent-icon="true"></span></a>
                    <ul className="uk-nav-sub">
                      <li>
                        <NavLink className="text_m" to="/graphic">
                          <span>{t("Sevices.1")}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="text_m" to="/web-design">
                          <span>{t("Sevices.3")}</span>
                        </NavLink>
                        <ul>
                          <li>
                            <NavLink className="text_m" to="/web-development">
                              <span>{t("Sevices.4")}</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="text_m" to="/book">
                              <span>{t("Sevices.6")}</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="text_m" to="/app-design">
                              <span>{t("Sevices.7")}</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="text_m" to="/smm">
                              <span>{t("Sevices.2")}</span>
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <NavLink className="text_m" to="/our-projects">
                    <span>{t("HOME.3")}</span>
                  </NavLink>
                  <li className="uk-parent">
                    <a href="/#"><span>{t('HOME.4')}</span> <span uk-nav-parent-icon="true"></span></a>
                    <ul className="uk-nav-sub">
                      <li> <NavLink className="text_m" to="/graphic-course"> <span> {t("Sevices.9")}</span> </NavLink></li>
                      <li> <NavLink className="text_m" to="/ui-ux"> <span>{t("Sevices.10")}</span></NavLink></li>
                      <li> <NavLink className="text_m" to="/web-course"> <span>{t("Sevices.8")}</span></NavLink></li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="link_container">
                <p>
                  <NavLink className="text_m" to="/contact-us">
                    <span>{t("HOME.5")}</span>
                  </NavLink>
                </p>
              </div>
              <div className="footer_container">
                <div className="footer_box">
                  <div className="contact_text">
                    <p>info@lightdesignestudio.am</p>
                    <p>+374 77 18 89 19,  +374 77 89 10 98 </p>
                    <p>Yerevan, Armenia, Gevorg Kochar 21</p>
                  </div>
                  <div className="contact_icon">
                    <a href="https://www.facebook.com/lightdesignestudio" target="blank"> <p className="logo"><FaFacebookF /></p></a>
                    <a href="https://instagram.com/light.design.studio?igshid=NTc4MTIwNjQ2YQ==" target="blank"> <p className="logo"> <AiOutlineInstagram /> </p> </a>
                    <a href="/#"><p className="logo"><FaTwitter /></p> </a>
                    <a href="https://www.behance.net/lightdesign" target="blank"><p className="logo"><BsBehance /></p></a>
                    <a href="/#"><p className="logo"><FaPinterestP /></p></a>
                    <a href="https://www.linkedin.com/company/90788683/admin/" target="blank"><p className="logo"><BiLogoLinkedin /></p></a>
                  </div>
                  <div className="date-of-creation">
                    <span className="data_p">2019 Light Design Studio</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      <div className="container_home">
        <div className="navbar_1">
          <div className="nav_cont">
            <div className="logo_home">
              <NavLink className="text_m" to="/">
                <img src={logoHome} className="logoBalck" alt="" />
              </NavLink>
            </div>
            <div className="menu-nav">
              <NavLink className="link_l" to="/about">
                <span>{t('HOME.1')}</span>
              </NavLink>
              <div className="link_l pointer" >
                <span className="link_l"> {t("HOME.2")}</span>
                <div
                  className="uk-card uk-card-body uk-card-default card-hover"
                  uk-drop="animate-out: true"
                >
                  <div className="link_container_service">
                    <p className="link_p_sevice_h">
                      <NavLink className="navL_h" to="/graphic">
                        <span>{t("Sevices.1")}</span>
                      </NavLink>
                    </p>
                    <p className="link_p_sevice_h">
                      <NavLink className="navL_h" to="/web-design">
                        <span>{t("Sevices.3")}</span>
                      </NavLink>
                    </p>
                    <p className="link_p_sevice_h">
                      <NavLink className="navL_h" to="/book">
                        <span>{t("Sevices.6")}</span>
                      </NavLink>
                    </p>
                    <p className="link_p_sevice_h">
                      <NavLink className="navL_h" to="/app-design">
                        <span>{t("Sevices.7")}</span>
                      </NavLink>
                    </p>

                    <p className="link_p_sevice_h">
                      <NavLink className="navL_h" to="/smm">
                        <span>{t("Sevices.2")}</span>
                      </NavLink>
                    </p>
                    <p className="link_p_sevice_h">
                      <NavLink className="navL_h" to="/web-development">
                        <span>{t("Sevices.4")}</span>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
              <NavLink className="link_l" to="/our-projects">
                <span>{t("HOME.3")}</span>
              </NavLink>
              <div >
                <NavLink to="/school" className="uk-inline link_n_h">
                  <span className="link_l">{t("HOME.4")}</span>
                </NavLink>
                <div
                  className="uk-card uk-card-body uk-card-default card-hover"
                  uk-drop="animate-out: true"
                >
                  <span>
                    <NavLink className="link_n_h" to="/graphic-course">
                      <span>{t("Sevices.9")} </span>
                    </NavLink>
                  </span>
                  <span>
                    <NavLink className="link_n_h" to="/ui-ux">
                      <span>{t("Sevices.10")} </span>
                    </NavLink>
                  </span>
                  <span>
                    <NavLink className="link_n_h" to="/web-course">
                      <span>{t("Sevices.8")} </span>
                    </NavLink>
                  </span>
                </div>
              </div>
              <NavLink className="link_l" to="/contact-us">
                <span>{t("HOME.5")}</span>
              </NavLink>
            </div>
            <LenguageSwitcher />
          </div>
        </div>
        <div className="logo_container_images">
          <NavLink to="/about">
            <div className="cover-home"></div>
          </NavLink>
        </div>
        <div className="home_menu">
          <div className="menu_text">
            <NavLink className="text_m" to="/graphic">
              <div className="elipse_cont">
                <img src={elipse} className="elipse" alt="" />
              </div>
              <span>{t("Sevices.1")}</span>
            </NavLink>
            <NavLink className="text_m" to="/web-design">
              <div className="elipse_cont">
                <img src={elipse} className="elipse" alt="" />
              </div>
              <span>{t("Sevices.3")}</span>
            </NavLink>
            <NavLink className="text_m" to="/smm">
              <div className="elipse_cont-smm">
                <img src={elipse} className="elipse" alt="" />
              </div>
              <span>{t("Sevices.2")}</span>
            </NavLink>
            <NavLink className="text_m" to="/web-development">
              <div className="elipse_cont">
                <img src={elipse} className="elipse" alt="" />
              </div>
              <span>{t("Sevices.4")}</span>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
