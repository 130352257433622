import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import transletEn from  "../src/trickster/en/translationEN.json";
import transletAm from  "../src/trickster/am/translationAM.json";

const resources = {
  en: {
    translation: transletEn,
  },
  am: {
    translation: transletAm,
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: "en",
  resources,
  detection: {
     caches:['cookie']
  }});


  

  



export default i18n;
