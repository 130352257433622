import Aos from "aos";
import { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import UIkit from "uikit";
 import "../Jointeam/join.css"
import "uikit/dist/css/uikit-core.min.css";
import "uikit/dist/css/uikit.min.css";
import { NavLink } from "react-router-dom";

function JointTeam() { 
   const [x, setX] = useState(null);
   const [y, setY] = useState(null);
  useEffect(()=>{
     Aos.init({duration:500})
  },[])
  UIkit.modal()
 
 
  const handleMouseMove =(e) => {
    const btn = e.target;
    const rect = btn.getBoundingClientRect()
     let  x = e.pageX - rect.left;
      let  y = e.clientY - rect.top;
       setX(x + 'px');
        setY(y + 'px'); 
        
  }
 
//  const {t} = useTranslation()

    return (
        <>
            <div className="join_register_btn">
                <div className="for_join_text">
                    <div className="titel_join">
                        <h2 data-aos="fade-right" className="main-titel_service">Join our team</h2>
                    </div>
                    <div className="text_join_btn_p">
                        <p data-aos="fade-right" className="join_text_p">
                            Our company offers a dynamic and inclusive work culture that values employee growth and
                            well-being. With abundant opportunities for career advancement, competitive compensation,
                            and comprehensive benefits, we foster a healthy work-life balance and exciting projects. We
                            take pride in our ethical practices, diverse workforce, and supportive leadership, making us
                            a top choice for individuals seeking a fulfilling and rewarding career
                        </p>
                        <NavLink 
                        className=" btn_join" 
                        to="/wiewcareers" 
                        onMouseMove={handleMouseMove}> 
                          <div  
                          className="animation_div"
                          style={{
                          position: 'absolute  ',
                          top: `${y}`,
                          left: `${x}`,
                          width: '0',
                          height: '0',
                          background: 'black',
                          borderRadius:'50%',
                          transform: `translate(-50%, -50%)`,
                          transition: '1s'
                        }}/>        
                            <span> VIEW CAREERS</span> 
                              
                          </NavLink>
      
                                        </div>
                                </div>
                    </div>
                   
        </>
    );
}

export default JointTeam;
