import Nvabar from "../Navbar/Nvabar";
import Register from "../Register/Register";
import "../Webcurse/webCurse.css";

import code from "../../assets/images/Rectangle 20 (2).png";
// import programCurse from "../src/imges/Web-development-course-PROGRAM.png";
import {useTranslation} from "react-i18next";
import Footer from "../page/Footer/Footer";
import { useEffect } from "react";
import Aos from "aos";

function WebCurse() {
    const {t} = useTranslation()
    useEffect(()=>{
        Aos.init({duration:500})
     },[])
    return (
        <>
            <Nvabar/>
            <div className="container_cover_webcurse">
                <div className="web_curse-cover1">
                    <div className="web-curse-text1">
                        <div className="titel-container-wb">
                            <h2 data-aos="fade-right" className="titel_wb">{t("WebCurse_.1")}</h2>
                        </div>
                        <div className="text_container_pvb1">
                            <p data-aos="fade-right" className="row-wb1">
                                {t("WebCurse_.2")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="Why_study_with_us_wb">
                <div className="titel_study">
                    <h2 data-aos="fade-right" className="titel_wb">{t("WebCurse_.3")}</h2>
                </div>
                <ul className="wb-stud">
                    <li data-aos="fade-right" className="list_wb">
                    {t("WebCurse_.4")}
                    </li>
                    <li data-aos="fade-right" className="list_wb">
                    {t("WebCurse_.5")}
                    </li>
                   
                    <li data-aos="fade-right" className="list_wb">
                    {t("WebCurse_.6")}
                    </li>
                </ul>
                <div className="text_2_wb">
                    <p data-aos="fade-right"> {t("WebCurse_.7")}</p>
                </div>

            </div>
            <div className="develop-imges">
                <img src={code} className="code" alt="code"/>
            </div>
            <div className="curse-program-conainer">

                <div className="curse_i">
                    <div className="curseTitel">
                        <h2 data-aos="fade-right">{t("WebCurse.31")}</h2>
                    </div>
                    <div className="grid-list">
                        <div className="uk-card uk-card-default  uk-card-hover uk-card-body">
                            <ul className="curse-pr">
                                <h3 data-aos="fade-right"> {t("WebCurse.0")}</h3>
                                <li data-aos="fade-right" className="list_wb">{t("WebCurse.1")}</li>
                                <li data-aos="fade-right" className="list_wb">{t("WebCurse.2")}</li>
                                <li data-aos="fade-right" className="list_wb">{t("WebCurse.3")}</li>
                                <li data-aos="fade-right" className="list_wb">{t("WebCurse.4")}</li>
                                <li data-aos="fade-right" className="list_wb">{t("WebCurse.5")}</li>

                            </ul>
                        </div>
                        <div>
                            <div className="uk-card  uk-card-default uk-card-hover uk-card-body">
                                <ul className="curse-pr">
                                    <h3 data-aos="fade-right">{t("WebCurse.6")}</h3>

                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.7")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.8")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.9")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.10")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.11")}</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                <ul className="curse-pr">
                                    <h3 data-aos="fade-right">{t("WebCurse.12")}</h3>
                                    <li data-aos="fade-right" className="list_wb"> {t("WebCurse.13")}</li>
                                    <li ata-aos="fade-right" className="list_wb">{t("WebCurse.14")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.15")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.16")}</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card  uk-card-default uk-card-hover uk-card-body">
                                <ul className="curse-pr">
                                    <h3 data-aos="fade-right">{t("WebCurse.17")}</h3>

                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.18")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.19")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.20")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.21")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.22")}</li>
                                     
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover uk-card-body ">
                                <ul className="curse-pr">
                                    <h3 data-aos="fade-right">{t("WebCurse.23")}</h3>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.24")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.25")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.26")}</li>
                                    <li data-aos="fade-right" className="list_wb">{t("WebCurse.27")}</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                <ul className="curse-pr">
                                    <h3 data-aos="fade-right"> {t("WebCurse.28")}</h3>
                                    <li data-aos="fade-right" className="list_wb"> 
                                    {t("WebCurse.29")}
                                    </li>
                                    <li data-aos="fade-right" className="list_wb">
                                    {t("WebCurse.30")}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                                
                </div>
                
            </div>
            <div className="curse-program-conainer">

<div className="curse_i">
    <div className="curseTitel">
        <h2 data-aos="fade-right">{t("PyyhonCurse.0")}</h2>
    </div>
    <div className="grid-list">
        <div className="uk-card uk-card-default  uk-card-hover uk-card-body">
            <ul className="curse-pr">
                <h3 data-aos="fade-right"> {t("PyyhonCurse.1")}</h3>
                <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.2")}</li>
                <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.3")}</li>
                <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.4")}</li>
                <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.5")}</li>

            </ul>
        </div>
        <div>
            <div className="uk-card  uk-card-default uk-card-hover uk-card-body">
                <ul className="curse-pr">
                    <h3 data-aos="fade-right">{t("PyyhonCurse.6")}</h3>
                    <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.7")}</li>
                    <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.8")}</li>
                    <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.9")}</li>
                    
                </ul>
            </div>
        </div>
        <div>
            <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                <ul className="curse-pr">
                    <h3 data-aos="fade-right">{t("PyyhonCurse.10")}</h3>
                    <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.11")}</li>
                    <li ata-aos="fade-right" className="list_wb">{t("PyyhonCurse.12")}</li>
                    <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.19")}</li>
                </ul>
            </div>
        </div>
        <div>
            <div className="uk-card  uk-card-default uk-card-hover uk-card-body">
                <ul className="curse-pr">
                    <h3 data-aos="fade-right">{t("PyyhonCurse.13")}</h3>
                    <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.14")}</li>
                    <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.15")}</li>
                    <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.16")}</li>
                </ul>
            </div>
        </div>
        <div>
            <div className="uk-card uk-card-default uk-card-hover uk-card-body ">
                <ul className="curse-pr">
                    <h3 data-aos="fade-right">{t("PyyhonCurse.17")}</h3>
                    <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.18")}</li>
                    <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.20")}</li>
                    <li data-aos="fade-right" className="list_wb">{t("PyyhonCurse.21")}</li>
                </ul>
            </div>
        </div>
        <div>
            <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                <ul className="curse-pr">
                    <h3 data-aos="fade-right">{t("PyyhonCurse.22")}</h3>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.23")}</li>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.24")}</li>
                </ul>
            </div>
        </div>
        <div>
            <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                <ul className="curse-pr">
                    <h3 data-aos="fade-right">{t("PyyhonCurse.25")}</h3>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.26")}</li>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.27")}</li>

                </ul>
            </div>
        </div>
        <div>
            <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                <ul className="curse-pr">
                    <h3 data-aos="fade-right">{t("PyyhonCurse.28")}</h3>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.29")}</li>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.30")}</li>

                </ul>
            </div>
        </div>
        <div>
            <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                <ul className="curse-pr">
                    <h3 data-aos="fade-right">{t("PyyhonCurse.31")}</h3>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.32")}</li>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.33")}</li>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.34")}</li>


                </ul>
            </div>
        </div>
        <div>
            <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                <ul className="curse-pr">
                    <h3 data-aos="fade-right">{t("PyyhonCurse.35")}</h3>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.36")}</li>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.37")}</li>
                </ul>
            </div>
        </div>
        <div>
            <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                <ul className="curse-pr">
                    <h3 data-aos="fade-right">{t("PyyhonCurse.38")}</h3>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.39")}</li>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.40")}</li>
                </ul>
            </div>
        </div>
        <div>
            <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                <ul className="curse-pr">
                    <h3 data-aos="fade-right">{t("PyyhonCurse.41")}</h3>
                    <li data-aos="fade-right" className="list_wb"> {t("PyyhonCurse.42")}</li>
                </ul>
            </div>
        </div>
    </div>

                
</div>

</div>
            <Register/>
            <Footer/>
        </>
    );
}

export default WebCurse;
