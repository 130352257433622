import React, { useState } from "react";
import "../StWorks/studentWork.css";
import { studentsWork } from "../../imges";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import icon from "../../assets/images/Logo/back.png";
import { NavLink } from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"
import Modal from 'react-modal'
// import { luminis} from "../../imgesWork";
import { useMediaPredicate } from "react-media-hook";
import { MdClose } from 'react-icons/md';
function StudentWork() {
  
  const [activeSlider, setactiveSlider] = useState([]);
  const biggerThan = useMediaPredicate("(max-width: 768px)");

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// let subtitle;

const [modalIsOpen, setIsOpen] = useState(false);

function openModal() {
  setIsOpen(true);
}


function closeModal() {
  setIsOpen(false);
}
  return (
    <>
         
                <>
                 <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                      ariaHideApp={false}
                     >        
                   

                   <div className="slider_container_or_btn">
                    <div className="clos_btn_container">
                    <MdClose onClick={closeModal} className="close_path"/>
                    </div>
                   
                   <ImageGallery disableThumbnailScroll={false}  thumbnailPosition = {biggerThan ? "bottom" : "left"} items = {activeSlider}/>
                   </div>
                  
                  </Modal>
               
               
                </>
            
  
                <>
          <div className="bac-btn">
              <NavLink  to="/graphic-course">
              <button className="back">
                <img src={icon} className="icon-back" alt="" />
              </button>
            </NavLink>
          </div>
                 {
                        studentsWork.map((data,i)=>{
                          return (
                            <>
                                <div key={i}>
                                  <img 
                                      id={data.id}
                                    src={data.img} alt="images" 
                                    className="work-imges"
                                  onClick={() => {
                                            
                                        
                                          setactiveSlider(data.id);
                                          openModal() 
                                          console.log(activeSlider);                
                                    }}
                                      />
                                  
                              </div>
                            </>
                          )
                        })
                      }
                      
                    </>
      
     
    </>
  );
}

export default StudentWork;
