import React, { useEffect,useState, useRef  } from "react";
import Nvabar from "../Navbar/Nvabar";
import Footer from "../page/Footer/Footer";
import "../UiUX/uiux.css";
import Aos from "aos";
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { AiFillLike } from 'react-icons/ai';
import "../Register/register.css";

    
const CoursesOption = [
  { value: 'Graphic Design ', label: 'Graphic Design ' },
  { value: 'UI/UX Design', label: 'UI/UX Design' },
  { value: 'Web Development', label: 'Web Development' },
];

const CoursesType = [
  { value: 'Offline', label: 'Offline' },
  { value: 'Online', label: 'Online' },
];
function UiUxdesign() {
     const {t} = useTranslation()
     useEffect(()=>{
      Aos.init({duration:500})
   },[])

   const formCurr = useRef();
   const { register, handleSubmit, reset, control, formState: { errors } } = useForm({
     shouldFocusError: false, 
   });
 
   const [alertshow, setalertshow] = useState(false);
   const [disabledBtn, setdisabledBtn] = useState(false);
   const [x, setX] = useState(null);
   const [y, setY] = useState(null);
 
   const handleMouseMove = (e) => {
     const btn = e.target;
     const rect = btn.getBoundingClientRect()
     let x = e.pageX - rect.left;
     let y = e.clientY - rect.top;
     setX(x + 'px');
     setY(y + 'px');
   }
 
   const onSubmit = () => {
     const Showelement = document.getElementById('sucsess_mode');
     if (Showelement) {
       setTimeout(() => {
         Showelement.scrollIntoView({ behavior: 'smooth' , block: "start", inline: "nearest" });
       });
     }
     sendEmail();
     setdisabledBtn(true);
     setalertshow(true);
   };
 
   const HandleRefresh=()=>{
     setalertshow(false);
     setdisabledBtn(false);
     const Hideelement = document.getElementById('hide_mode');
     if (Hideelement) {
       Hideelement.scrollIntoView({ behavior: 'smooth' });
     }
   }
 
   const sendEmail = () => {
     emailjs.sendForm('service_fqwggkv', 'template_evtejcs', formCurr.current, 'yk8KZOUcnNu0EV98F')
       .then((result) => {
         setTimeout(() => {
           reset();
           setalertshow(false);
           setdisabledBtn(false);
         }, 2000);
       }, (error) => {
         console.log(error.text);
       });
   };

  return (
    <>
      <Nvabar />
      <div className="container_uiux">
        <div className="cover1_container">
          <div className="uiux_cover1"></div>
        </div>
        <div className="text-uiux1-container">
          <div className="titel_h2_ui">
            <h2 data-aos="fade-right" >{t("ux_curse_titel.1")}</h2>
          </div>
          <div data-aos="fade-right" className="ui_text1_container">
            <p className="ui_text1">
            {t( "ux_c.1")}
            </p>
          </div>
          <div className="ui-ux-container2">
          <div className="uiux-cover-container2">
            <div className="ui-ux-text-container2">
              <div className="Why-Study-With-Us-T">
                <h2 data-aos="fade-right" > {t( "Why_study_with_us.01")} </h2>
              </div>
              <div className="our_curse_ux">
                <ul>
                  <li data-aos="fade-right" className="list_gr_c">
                   {t("Why_study_with_us.011") } 
                  </li>
                  <li data-aos="fade-right" className="list_gr_c">
                  {t("Why_study_with_us.2") } 
                  </li>
                  <li data-aos="fade-right" className="list_gr_c">
                  {t("Why_study_with_us.3") } 
                  </li>
                </ul>
              </div>
            </div>
            <div className="ux-prog_container">
          <div className="our_trainer_text_ui">
                <div className="titel_t_o">
                  <h2 data-aos="fade-right" >{t( "Gr_curs.1") }</h2>
                </div>
                <ul className="ux_ul_li">
                  <li data-aos="fade-right" className="list_gr_c">
                      {t("UxCurse.1")}
                  </li>
                  <li data-aos="fade-right" className="list_gr_c">
                  {t("UxCurse.2")}
                  </li>
                  <li data-aos="fade-right" className="list_gr_c">
                  {t("UxCurse.3")}

                  </li>
                  <li data-aos="fade-right" className="list_gr_c">
                  {t("UxCurse.4")}
                  </li>
                  <li data-aos="fade-right" className="list_gr_c">
                  {t("UxCurse.5")}
                  </li> 
                    <li data-aos="fade-right" className="list_disk_not">
                    <div className="trainer_ui">
                <p className="profession_trainer">
                  <span>{t("ux_curse_titel.2")}:&nbsp;</span>
                  <a
                    href="https://www.behance.net/lusymikaelyan"
                    target="blank"
                  > 
                  https://www.behance.net/lusymikaelyan
                  </a>{" "}
                </p>
              </div>
                    </li>
                </ul>
              </div>
            </div>
          </div>
         
        </div>
         
              
        </div>
        
          <div className="ux-container-curse">
             <div className="titel_ux_curse"><h2 data-aos="fade-right" >{t("UxCurse.01")}</h2></div>
              <div className="curse_container_2">
                <div className="curset_txt_contin">
                  <div className="gris-list">
                     <div className="titel_curse_1"> <h4 data-aos="fade-right"  className="curse_name_1">{t("UXcurseBeginner.1")}</h4></div>
                     <div className="curse_plan">
                      <p data-aos="fade-right" className="list_wb ">
                      {t("UXcurseBeginner.02")}
                      </p>
                      <p data-aos="fade-right" className="list_wb ">
                      {t("UXcurseBeginner.03")}
                      </p>
                      <p data-aos="fade-right" className="list_wb ">
                      {t("UXcurseBeginner.04")}
                      </p>
                     </div>
                     
                  </div>
                  <div className="gris-list">
                     <div className="titel_curse_1"> <h4 data-aos="fade-right"  className="curse_name_1">{t("UXcurseBeginner.3")}</h4></div>
                     <div className="curse_plan">
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.4")}
                      </p>
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.5")}
                      </p>
                      <ul>
                        <li data-aos="fade-right" > {t("UXcurseBeginner.6")}</li>
                        <li data-aos="fade-right" > {t("UXcurseBeginner.7")}</li>
                        <li data-aos="fade-right" > {t("UXcurseBeginner.8")}</li>
                        <li data-aos="fade-right" > {t("UXcurseBeginner.9")}</li>
                        <li data-aos="fade-right" > {t("UXcurseBeginner.10")}</li>
                        <li data-aos="fade-right" > {t("UXcurseBeginner.11")}</li>
                        <li data-aos="fade-right" > {t("UXcurseBeginner.12")}</li>
                        <li data-aos="fade-right" > {t("UXcurseBeginner.13")}</li>

                      </ul>
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.14")}
                      </p>
                     </div>
                     
                  </div>
                  <div className="gris-list">
                     <div className="titel_curse_1"> <h4 data-aos="fade-right"  className="curse_name_1">{t("UXcurseBeginner.15")}</h4></div>
                     <div className="curse_plan">
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.16")}
                      </p>
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.17")}
                      </p>
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.18")}
                      </p>
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.19")}
                      </p>
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.20")}
                      </p>
                     </div>
                     
                  </div>
                  <div className="gris-list">
                     <div className="titel_curse_1"> <h4 data-aos="fade-right"  className="curse_name_1">{t("UXcurseBeginner.21")}</h4></div>
                     <div className="curse_plan">
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.22")}
                      </p>
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.23")}
                      </p> 
                       <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.24")}
                      </p> 
                       <p  data-aos="fade-right" className="list_wb ">
                      {t("UXcurseBeginner.25")}
                      </p>  
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.26")}
                      </p>  
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.27")}
                      </p>  
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.28")}
                      </p>  
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.29")}
                      </p>  
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.30")}
                      </p>  
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.31")}
                      </p>  
                     </div>
                     
                  </div>
                  <div className="gris-list">
                     <div className="titel_curse_1"> <h4 data-aos="fade-right"  className="curse_name_1">{t("UXcurseBeginner.32")}</h4></div>
                     <div className="curse_plan">
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.33")}
                      </p>
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.34")}
                      </p>
                      <p data-aos="fade-right"  className="list_wb ">
                      {t("UXcurseBeginner.35")}
                      </p>
                     </div>
                     
                  </div>
                  </div>
                </div>
            
          </div>
       
      </div>
      {/* <Register /> */}
      <div className="register_container" id="hide_mode">
        <div className={alertshow ? "alert_container show" : "alert_container"} id="sucsess_mode">
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              <button 
                type="button" 
                className="close" 
                aria-label="Close"
                onClick={()=> HandleRefresh()}
                >
                <span aria-hidden="true">×</span>
              </button>
              <span className="alert-inner--icon">
                <AiFillLike />
              </span>
              <span className="alert-inner--text ml-1">
                <strong>Success!</strong> 
                Thanks for filling out our form!
              </span>
            </div>
        </div>
        <form className="row g-3 register" onSubmit={handleSubmit(onSubmit)} ref={formCurr}>
          <div className="reg_titel_container">
            <div className="titel_register">
              <h1 className="titele_r">
                {t("Register_1.66")} <span className="t_h1"> {t("Register_1.21")}</span>
              </h1>
              <h1 className="titele_r">
                {t("Register_1.2")} <span className="t_h1"> {t("Register_1.02")}</span>
              </h1>
              <h3 className="reg_n"> {t("Register_1.3")}</h3>
            </div>
          </div>
          <div className="cont_user-name_lastname">
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.4")}
              </span>
              <div className={errors?.user_name?.type === "required" ? "form-block  has-error" : "form-block"}  >
                <input placeholder="Name" className="form-control" name="user_name" {...register("user_name", { required: true })} />
                <p className="error-info" >This field is required</p>
              </div>
            </div>
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.6")}
              </span>
              <div className={errors?.user_surname?.type === "required" ? "form-block has-error" : "form-block"}  >
                <input placeholder="Surname" className="form-control" name="user_surname" {...register("user_surname", { required: true })} />
                <p className="error-info" >This field is required</p>
              </div>
            </div>
          </div>
          <div className="cont_user-name_lastname">
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.7")}
              </span>
              <div className="input-group">
                <div className={errors?.user_email?.type === "required" || errors?.user_email?.type === "pattern" ? "mail_inline form-block has-error" : "mail_inline form-block"}  >
                  <span className="input-group-text" id="inputGroupPrepend2">
                    @
                  </span>
                  <input placeholder="Email" className="form-control" name="user_email" {...register("user_email", { required: true, pattern: /^\S+@\S+$/i })} />
                  {errors?.user_email?.type === "pattern" ? <p className="error-info email-info" >invalid Email</p> :
                    <p className="error-info" >This field is required</p>
                  }
                </div>
              </div>
            </div>
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.9")}
              </span>
              <div className="input-group">
                <div className={errors?.phone_number?.type === "required" ? "form-block has-error" : "form-block"}  >
                  <input    type="number" placeholder="Phone Number" className="form-control" name="phone_number" {...register("phone_number", { required: true })} />
                  <p className="error-info" >This field is required</p>
                </div>
              </div>
            </div>
          </div>

          <div className="cont_user-name_lastname">
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.11")}
              </span>
              <div className={errors?.city?.type === "required" ? "form-block has-error" : "form-block"}  >
                <input placeholder="City" className="form-control" name="city" {...register("city", { required: true })} />
                <p className="error-info" >This field is required</p>
              </div>
            </div>
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.13")}
              </span>
              <div className={errors?.state?.type === "required" ? "form-block has-error" : "form-block"}  >
                <input placeholder="state" className="form-control" name="state" {...register("state", { required: true })} />
                <p className="error-info" >This field is required</p>
              </div>
            </div>
          </div>
          <div className="cont_user-name_lastname">
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.15")}
              </span>
              <div className={errors?.course?.type === "required" ? "form_block sellect_section has-error" : "form_block"}  >
                <Controller
                  name="course"
                  control={control}
                  rules={{ required: 'Select Course' }}
                  render={({ field }) => (
                    <Select
                      className=" register_sellect"
                      options={CoursesOption}
                      placeholder="Select Course"
                      {...field}
                    />
                  )}
                />
                <p className="error-info" >This field is required</p>
              </div>
            </div>
            <div className="reg_box">
              <span  className="form-label">
                {t("Register_1.16")}
              </span>
              <div className={errors?.course_type?.type === "required" ? "form_block sellect_section has-error" : "form_block"}  >
                <Controller
                  name="course_type"
                  control={control}
                  rules={{ required: 'Select Course' }}
                  render={({ field }) => (
                    <Select
                      className=" register_sellect"
                      options={CoursesType}
                      placeholder="Select Course"
                      {...field}
                    />
                  )}
                />
                <p className="error-info" >This field is required</p>
              </div>
            </div>
          </div>
          <div className="form-floating textarea_floating">
            <span  className="form-label">
              Comment
            </span>
            <textarea name="comment" placeholder="Message" className="form-control textarea_input" />
            {/* <div className={errors?.comment?.type === "required" ? "form-block  has-error" : "form-block"}  >
              <p className="error-info" >This field is required</p>
            </div> */}
          </div>
          <div className="col-12 buton_register">
            <button disabled={disabledBtn} className="btn_join" onMouseMove={handleMouseMove}>
              <div
                className="animation_div"
                style={{
                  position: 'absolute  ',
                  top: `${y}`,
                  left: `${x}`,
                  width: '0',
                  height: '0',
                  background: 'black',
                  borderRadius: '50%',
                  transform: `translate(-50%, -50%)`,
                  transition: '1s'
                }} />
              <span>{t("Register_1.18")}</span>
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default UiUxdesign;
