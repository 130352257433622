import "../../components/Graphic/graphic.css";
import Footer from "../page/Footer/Footer";
import Nvabar from "../Navbar/Nvabar";
import JointTeam from "../Jointeam/JoinTeam";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import { useEffect } from "react";

function Graphic() {
  const {t} = useTranslation()
  useEffect(()=>{
    Aos.init({duration:500})
 },[])
  return (
    <>
      <Nvabar />
      <div className="grapihic_container">
        <div className="cover_graphic_1">
          <div className="text_graphic_box">
            <div className="titel_graphic_box">
              <h2 data-aos="fade-right" className="main-titel_service">{t("Sevices.1")}</h2>
            </div>
            <div data-aos="fade-right" className="graphic_text_g">
              <p className="text_gr">
                       {t("Graphic.1")}
              </p>
            </div>
          </div>
        </div>
        <div className="our_graphic">
          <div className="graphic_text_box2">
            <div className="gr_titiel">
              <h2 data-aos="fade-right" className=" main-titel_service_v">
              {t("Graphic.2")}
              </h2>
            </div>
            <div  data-aos="fade-right" className="gr_text_2">
              <p className="text_gr">
              {t("Graphic.3")}
              </p>
             
            </div>
          </div>
        </div>
        <div className="cover3_gr_image">
          <div className="cover3_gr">
          <JointTeam />
          </div>
        </div>
     
      </div>
      <Footer />
    </>
  );
}

export default Graphic;
